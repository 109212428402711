import { Link } from "react-router-dom";
import styled from "styled-components";

// Elements
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
// Stacks
export const HStack = styled.div`
  display: flex;
  flex-direction: row;
  height: ${({ h }) => (h ? h : null)};
  width: ${({ w }) => (w ? w : null)};
  align-items: ${({ align }) => (align ? align : null)};
  justify-content: ${({ justify }) => (justify ? justify : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  & > *:not(:last-child) {
    margin-right: ${({ itemSpace }) => (itemSpace ? itemSpace : null)};
    margin-bottom: 0px;
  }
  @media (max-width: ${({ responsive }) => (responsive ? responsive : null)}) {
    flex-direction: column;
    height: ${({ rh }) => (rh ? rh : null)};
    width: ${({ rw }) => (rw ? rw : null)};
    align-items: ${({ rAlign }) => (rAlign ? rAlign : null)};
    justify-content: ${({ rJustify }) => (rJustify ? rJustify : null)};
    & > *:not(:last-child) {
      margin-bottom: ${({ rItemSpace }) => (rItemSpace ? rItemSpace : null)};
      margin-right: 0px;
    }
  }
`;
export const VStack = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: ${({ h }) => (h ? h : null)};
  width: ${({ w }) => (w ? w : null)};
  align-items: ${({ align }) => (align ? align : null)};
  justify-content: ${({ justify }) => (justify ? justify : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  & > *:not(:last-child) {
    margin-bottom: ${({ itemSpace }) => (itemSpace ? itemSpace : null)};
    margin-right: 0px;
  }
  @media (max-width: ${({ responsive }) => (responsive ? responsive : null)}) {
    /* flex-direction: row; */
    height: ${({ rh }) => (rh ? rh : null)};
    width: ${({ rw }) => (rw ? rw : null)};
    & > *:not(:last-child) {
      /* margin-right: ${({ rItemSpace }) =>
        rItemSpace ? rItemSpace : null}; */
      /* margin-bottom: 0px; */
      margin-bottom: ${({ rItemSpace }) => (rItemSpace ? rItemSpace : null)};
      /* margin-bottom: 0px; */
    }
    flex-direction: ${({ direction }) => (direction ? direction : null)};
    align-items: ${({ rAlign }) => (rAlign ? rAlign : null)};
    justify-content: ${({ rJustify }) => (rJustify ? rJustify : null)};
  }
`;

//Buttons
export const PrimaryButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 48px;
  background: #000000;
  color: #ffffff;
  border: 2px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  svg {
    margin-left: 5px;
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    padding: 8px 12px;
  }
`;

export const OutlineButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 48px;
  border: 2px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  svg {
    margin-left: 5px;
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    padding: 8px 12px;
  }
`;

export const SmallButton = styled(PrimaryButton)`
  padding: 8px 12px;
  border: none;
`;

export const InputField = styled.div`
  width: 100%;
  position: relative;
`;
export const InputText = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 15px;
  width: 100%;
  background: #ffffff;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  &.error {
    border-color: red;
  }
`;
export const PrefixInputText = styled(InputText)`
  // used for Dr. prefix in reg pg
  span.prefix {
    color: black;
    margin-right: 5px;
  }
  input {
    flex-grow: 1;
    border: none;
    outline: none;
    padding: 0;
  }
`;

export const Textarea = styled.textarea`
  padding: 13px 15px;
  width: 100%;
  background: #ffffff;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  &.error {
    border-color: red;
  }
`;

export const ErrorMessage = styled.small`
  color: red;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 2px solid black;
  button {
    margin-left: auto;
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 767.98px) {
    /* padding-top: 2rem;
    margin-top: 3rem; */
  }
`;

// Typography
export const Heading = styled.h2`
  font-family: "Playfair Display", sans-serif;
  font-size: 36px;
`;

// Custom Radio Button
export const CustomRadio = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  user-select: none;

  & + & {
    margin-left: 1rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark:after {
      background-color: black;
    }
  }

  .checkmark {
    position: absolute;
    border: 2px solid black;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 3px;
    left: 0px;
    z-index: 5;
    transition: background 0.25s linear;

    &:after {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 12px;
      width: 12px;
      top: 0px;
      left: 0px;
      margin: auto;
      border: 2px solid #fff;
      transition: background 0.25s linear;
    }
    /* &.error {
      border-color: red;
    } */
  }
`;

// Custom Checkbox Button
export const CustomCheckbox = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  user-select: none;

  & + & {
    margin-left: 1rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark:after {
      border-color: black;
    }
  }

  .checkmark {
    position: absolute;
    border: 2px solid black;
    border-radius: 5px;
    height: 20px;
    width: 20px;
    top: 3px;
    left: 0px;
    z-index: 5;
    transition: border 0.25s linear;

    &:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 0px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      transition: border 0.25s linear;
    }
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    display: flex;
    & + & {
      margin-left: 0;
    }
  }
`;

// Custom File Upload
export const CustomFileUpload = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-bottom: 1rem; */
  border-radius: 5px;
  border: 2px solid black;
  width: 100%;
  /* max-height: 270px; */
  img {
    height: 270px;
    object-fit: cover;
    border-bottom: 2px solid black;
  }
  label {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    padding: 13px;
    color: #00000070;
    i {
      margin-left: auto;
    }
    input {
      border: none;
      display: none;
    }
  }
`;

// Lists
export const OrderedList = styled.ol`
  line-height: 36px;
  li {
    list-style: initial;
  }
`;
export const NumberedList = styled.ul`
  line-height: 36px;
  li {
    list-style: initial;
  }
`;

// List Group

export const ListGroup = styled.ol`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  list-style-type: none;
  counter-reset: section;
`;

export const ListGroupItem = styled.li`
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  & + & {
    border-top-width: 0;
  }
  &:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
  &:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
  }
`;

// Horizontal Line
export const Line = styled.hr`
  display: block;
  margin-top: 0em;
  margin-bottom: 0em;
  margin-left: auto;
  margin-right: auto;
  border-style: solid;
  border-width: 1px;
  width: 100%;
`;

// Dashboard
export const SearchBar = styled.label`
  display: flex;
  width: 220px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  @media (max-width: 575.98px) {
    width: 100%;
    margin-bottom: 1rem;
  }
  input {
    flex-grow: 1;
  }
`;
export const Filter = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  @media (max-width: 575.98px) {
    width: 100%;
  }
  span {
    margin-right: 10px;
  }
  select {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid black;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
      flex-grow: 1;
    }
  }
`;

// View Appointment
export const DoctorVideo = styled.video`
  width: 75%;
  height: auto;
`;
export const Card = styled.div`
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .card-header {
    padding: 13px 15px;
    border-bottom: 2px solid black;
  }

  img {
    width: 100%;
    object-fit: cover;
    margin: auto;
  }
`;
export const Icon = styled.img`
  /* width: auto; */
  /* height: auto; */
  margin: auto !important;
`;
export const IconColumn = styled(VStack)`
  text-align: center;
  align-items: center;
  align-self: start;
`;
export const StatusButton = styled.select`
  padding: 10px;
  border: 2px solid black;
  border-radius: 5px;
  margin-left: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
`;
export const RowCols = styled(HStack)`
  & > div {
    flex: 0 0 auto;
    width: 100%;
    padding: 1rem;
    @media (min-width: 576px) {
      width: 50%;
    }
    @media (min-width: 768px) {
      width: 33.3333%;
    }
    @media (min-width: 992px) {
      width: 25%;
    }
    @media (min-width: 1400px) {
      width: 16.6666%;
    }
  }
`;
export const OutlineLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 48px;
  border: 2px solid red;
  color: red;
  border-radius: 5px;
  cursor: pointer;
  svg {
    margin-left: 5px;
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    padding: 8px 12px;
  }
`;

// Recording Page
export const RecordControls = styled.div`
  position: fixed;
  left: 0;
  top: 50%;
  padding: 1rem;
  background: white;
  box-shadow: 0px -1px 4px 0px rgb(0 0 0 / 20%);
`;
export const Video = styled.video`
  max-width: 1080px;
  width: 100%;
`;
export const CameraVideo = styled.div`
  position: fixed;
  display: flex;
  width: 200px;
  height: 150px;
  bottom: 10%;
  right: 10%;
  border: 2px solid black;
`;
export const RecordIcon = styled.div`
  padding: 5px;
  display: flex;
  border: 2px solid black;
  border-radius: 30px;
  & + & {
    margin-top: 1rem;
  }
`;

// IntroVideo
export const InputFile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid black;
  border-radius: 5px;

  label {
    background: black;
    color: white;
    padding: 13px 18px;
  }
  button {
    background: black;
    color: white;
    padding: 13px 18px;
    cursor: pointer;
  }

  @media (max-width: 575.98px) {
    flex-direction: column;

    label,
    button,
    span {
      width: 100%;
      text-align: center;
    }
    span {
      padding: 1rem;
    }
  }
`;

// Account
export const NavTabs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: ${({ tabs }) => (tabs ? "1fr 1fr 1fr" : "1fr 1fr")};
  column-gap: 10px;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  button.active {
    background-color: black;
    color: white;
  }
`;
export const TabContent = styled.div`
  padding: 20px;
  border: 2px solid black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
export const ProfilePicture = styled.img`
  max-width: 480px;
  max-height: 380px;
  border-radius: 10px;
  box-shadow: 10px 10px 30px #d9d9d9, -10px -10px 30px #ffffff;
`;

// Error page
export const NotFound = styled.div`
  text-align: center;

  h1 {
    font-size: 250px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -35px;
    margin-top: -100px;
    span {
      text-shadow: -8px 0 0 #fff;
    }
  }

  button {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    padding: 2.75rem 1.5rem;
    h1 {
      font-size: 200px;
      letter-spacing: -30px;
      margin-left: -30px;
      margin-top: -80px;
    }
  }
`;

// Progress Box
export const ProgressBox = styled.div`
  padding: 1rem;
  border: 2px solid black;
  border-radius: 5px;
  p {
    margin-bottom: 1rem;
  }
  ul {
    margin-bottom: 1rem;
    li {
      display: flex;
      align-content: center;
      svg {
        margin-right: 5px;
      }
    }
    li + li {
      margin-top: 0.25rem;
    }
  }
`;
