import React from "react";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/Footer";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ViewAppointment from "./pages/ViewAppointment";
import Account from "./pages/Account";

function PatientIndex() {
  const location = useLocation();
  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/viewAppointment" element={<ViewAppointment />} />
        <Route exact path="/account" element={<Account />} />
        <Route
          path="/*"
          element={<Navigate to="/error" replace state={{ from: location }} />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default PatientIndex;
