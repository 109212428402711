import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import Axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Header,
  Heading,
  OutlineButton,
  PrimaryButton,
  DoctorVideo,
  Line,
  Card,
  VStack,
  HStack,
} from "../../styledComponents/GlobalElements";
import { Mail, Phone } from "react-feather";
import SelectCaseDisplay from "../../globalComponents/viewAppointmentComponents/SelectCaseDisplay";
import YourRecommendation from "../../globalComponents/viewAppointmentComponents/YourRecommendation";
import { GlobalContext } from "../../globalComponents/context/GlobalContextApi";

function ViewAppointment() {
  const navigate = useNavigate();
  const location = useLocation();
  const appointmentIDRef = useRef(location.state?.appointmentID);
  const mediaIDsRef = useRef([]);
  const suggestedTreatmentRef = useRef([]);
  const requiredAppointmentsRef = useRef([]);
  const investmentRef = useRef([]);

  const { imgBaseUrl } = useContext(GlobalContext);

  const [appointment, setAppointment] = useState({});
  const [patientsPics, setPatientsPics] = useState({});
  const [caseMedia, setCaseMedia] = useState([]);

  const fetchCaseMedia = () => {
    Axios({
      method: "POST",
      data: { mediaID: mediaIDsRef.current.toString() },
      withCredentials: true,
      url: "/patient/getCaseMedia",
    }).then((res) => {
      if (res.data.status) {
        setCaseMedia(res.data.result);
      }
    });
  };

  const fetchAppointmentDetails = useCallback(() => {
    Axios({
      method: "POST",
      data: { appointmentID: appointmentIDRef.current },
      withCredentials: true,
      url: "/patient/appointmentDetails",
    }).then((res) => {
      if (res.data.status) {
        mediaIDsRef.current = JSON.parse(res.data.result.Case_Media) || [];
        suggestedTreatmentRef.current =
          res.data.result.Suggested_Treatment || "";
        requiredAppointmentsRef.current =
          JSON.parse(res.data.result.Required_Appointments) || [];
        investmentRef.current = JSON.parse(res.data.result.Investment) || [];
        setPatientsPics(JSON.parse(res.data.result.Patient_Selfies));
        setAppointment(res.data.result);
        fetchCaseMedia();
      }
    });
  }, []);

  useEffect(() => {
    fetchAppointmentDetails();
  }, [location.search, fetchAppointmentDetails]);

  return (
    <VStack as="section">
      <VStack padding="1.5rem 1.5rem 0 1.5rem" className="m-w-1140">
        <Header>
          <h1>{appointment.Patient_Name}</h1>
          <OutlineButton onClick={() => navigate("/patient/dashboard")}>
            Dashboard
          </OutlineButton>
        </Header>
      </VStack>
      {appointment.Doctor_Video && (
        <VStack
          align="center"
          padding="1.5rem 1.5rem 0 1.5rem"
          itemSpace="1.5rem"
          className="m-w-1140"
        >
          <DoctorVideo
            controls
            src={`${imgBaseUrl}/recorded_videos/${appointment.Doctor_Video}`}
          ></DoctorVideo>
          <Line></Line>
        </VStack>
      )}
      <HStack
        itemSpace="1.5rem"
        padding="1.5rem"
        responsive="576px"
        rItemSpace="1.5rem"
        className="m-w-1140"
        w="100%"
      >
        <VStack w="50%" responsive="576px" rw="100%">
          <Card>
            <div className="card-header">Selfie</div>
            <img
              src={`${imgBaseUrl}/patient_pictures/${patientsPics.selfie}`}
              alt="Selfie"
            />
          </Card>
        </VStack>
        <VStack w="50%" responsive="576px" rw="100%">
          <Card>
            <div className="card-header">Close-up</div>
            <img
              src={`${imgBaseUrl}/patient_pictures/${patientsPics.closeup}`}
              alt="Close-up"
            />
          </Card>
        </VStack>
      </HStack>
      <VStack className="bg-grey" padding="3rem 0">
        <VStack padding="0 1.5rem" align="center" className="m-w-1140">
          <Heading className="text-center">
            What you would like to change about your smile?
          </Heading>
          <p>{appointment.Concern}</p>
        </VStack>
      </VStack>
      <VStack padding="1.5rem" itemSpace="1.5rem" className="m-w-1140">
        <HStack itemSpace="1.5rem" responsive="576px" rItemSpace="1.5rem">
          <VStack w="50%" responsive="576px" rw="100%">
            <Card className="w-100">
              <img
                src={`${imgBaseUrl}/profile_pictures/${appointment.Doctor_Picture}`}
                alt="ProfilePicture"
              />
            </Card>
          </VStack>
          <VStack w="50%" responsive="576px" rw="100%">
            <small>About Doctor</small>
            <Heading>{appointment.Doctor_Name}</Heading>
            {appointment.Intro_Summary && (
              <VStack padding="py-3">
                <p>{appointment.Intro_Summary}</p>
              </VStack>
            )}
            <p>
              <Mail size={24} /> {appointment.Doctor_Email}
            </p>
            <p>
              <Phone size={24} /> {appointment.Doctor_Phone}
            </p>
          </VStack>
        </HStack>
      </VStack>
      <VStack padding="0 1.5rem" className="m-w-1140">
        <Line></Line>
      </VStack>
      <VStack padding="1.5rem" itemSpace="1.5rem" className="m-w-1140">
        <VStack align="center">
          <small>Case Studies</small>
          <h2>Smile Makeovers</h2>
        </VStack>
        <SelectCaseDisplay images={caseMedia} />
      </VStack>
      <VStack padding="0 1.5rem" className="m-w-1140">
        <Line></Line>
      </VStack>
      <VStack padding="1.5rem" itemSpace="1.5rem" className="m-w-1140">
        <VStack align="center">
          <small>Alternate Text</small>
          <h2>Recommendations</h2>
        </VStack>
        {(suggestedTreatmentRef.current ||
          requiredAppointmentsRef.current ||
          investmentRef.current) && (
          <YourRecommendation
            edit={false}
            recommendations={{
              suggestedTreatment: suggestedTreatmentRef.current,
              requiredAppointments: requiredAppointmentsRef.current,
              investment: investmentRef.current,
            }}
          />
        )}
      </VStack>
      <VStack padding="0 1.5rem" className="m-w-1140">
        <Line></Line>
      </VStack>
      <HStack
        padding="1.5rem"
        justify="space-between"
        itemSpace="1rem"
        responsive="576px"
        rItemSpace="1.5rem"
        align="center"
        className="m-w-1140"
        w="100%"
      >
        {appointment && appointment.Status && (
          <PrimaryButton>Schedule an Appointment</PrimaryButton>
        )}
        <OutlineButton
          as="a"
          className="ml-auto"
          href={`mailto:${appointment.Doctor_Email}`}
        >
          Have a question? Contact Us!
        </OutlineButton>
      </HStack>
    </VStack>
  );
}

export default ViewAppointment;
