import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavbarBrand } from "./NavbarElements";
import Burger from "./Burger";

export default function Navbar() {
  return (
    <Nav>
      <NavbarBrand>
        <NavLink to="/patient/dashboard">MendSmile</NavLink>
      </NavbarBrand>
      <Burger />
    </Nav>
  );
}
