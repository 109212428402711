import React from "react";
import { VStack } from "../../styledComponents/GlobalElements";
import { ReactComponent as Celebration } from "../../assets/images/artwork/celebration.svg";
function ThankYou() {
  return (
    <VStack w="100%" h="100%" align="center" justify="center" padding="1rem">
      <Celebration />
      <p>Thank you for registering with MendSmile</p>
      <h1 className="text-center">Our team will get in touch with you soon.</h1>
    </VStack>
  );
}

export default ThankYou;
