import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavbarBrand } from "./NavbarElements";
import Burger from "./Burger";

export default function Navbar({ user }) {
  return (
    <Nav>
      <NavbarBrand>
        <NavLink to="/doctor/dashboard">MendSmiles</NavLink>
      </NavbarBrand>
      <Burger user={user} />
    </Nav>
  );
}
