import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import {
  HStack,
  OrderedList,
  VStack,
  PrefixInputText,
  InputText,
  ErrorMessage,
  InputField,
  CustomRadio,
  PrimaryButton,
} from "../../../styledComponents/GlobalElements";
import MultiSelectDropdown from "../../../globalComponents/multi-select-dropdown/MultiSelectDropdown";
import { ArrowRight } from "react-feather";
import { useFormValidation } from "../../../globalComponents/validation/useFormValidation";
import { validationStep1 } from "../../../globalComponents/validation/validationConstraints";

var formLoopData = {};

function Step1({ setCount, formFieldsRef }) {
  const [services, setServices] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const { handleValidation, errors } = useFormValidation({
    data: formLoopData,
    validations: {
      ...validationStep1,
      // special case for confirm pass as it requires other values
      confirmPassword: {
        required: {
          value: true,
          message: "Password is required",
        },
        custom: {
          isValid: (value) => value === formLoopData.password,
          message: "Passwords do not match",
        },
      },
    },
  });

  const handleDataForValidation = () => {
    var data = new FormData(document.getElementById("register-form"));

    for (var [key, value] of data) {
      formLoopData[key] = value;
    }
    formLoopData["services"] = JSON.stringify(selectedValues.map((i) => i.id));

    handleValidation(() => {
      // Callback fn runs on no errors

      formFieldsRef.current = {
        ...formFieldsRef.current,
        ...formLoopData,
      };
      setCount((prevCount) => prevCount + 1);
    });
  };

  const fetchAllDoctorServices = useCallback(async () => {
    await Axios({
      method: "GET",
      withCredentials: true,
      url: "/doctor/allDoctorServices",
    }).then((res) => {
      if (res.data.status) {
        let addService = [];
        Object.entries(res.data.result).forEach(([key, value]) => {
          addService.push({ id: value.Service_ID, label: value.Name });
        });
        setServices(addService);
        if (
          formFieldsRef.current.services &&
          JSON.parse(formFieldsRef.current.services).length
        ) {
          setSelectedValues(
            JSON.parse(formFieldsRef.current.services).map((i) =>
              addService.find((ele) => ele.id === i)
            )
          );
        }
      }
    });
  }, [formFieldsRef]);

  useEffect(() => {
    fetchAllDoctorServices();

    return () => {};
  }, [fetchAllDoctorServices]);

  return (
    <>
      <HStack w="100%" h="100%" responsive="576px">
        {/* left section */}
        <VStack
          w="50%"
          className="bg-lightGrey"
          padding="5rem"
          align="center"
          justify="center"
          responsive="576px"
          rw="100%"
        >
          <p>Step 1 of 2</p>
          <React.Fragment>
            <h1 className="text-center">Welcome to MendSmile!</h1>
            <p>By purchasing today, you'll gain access to:</p>
            <OrderedList>
              <li>Our exclusive community of doctors</li>
              <li>Masterclasses</li>
              <li>Ebooks</li>
              <li>Videos</li>
              <li>Dedicated success team</li>
              <li>Exclusive map directory placement</li>
              <li>Minimum 6 month commitment</li>
            </OrderedList>
          </React.Fragment>
        </VStack>
        {/* right section */}
        <VStack
          w="50%"
          as="form"
          id="register-form"
          padding="1rem"
          align="center"
          justify="center"
          itemSpace="1rem"
          responsive="576px"
          rw="100%"
        >
          <HStack
            itemSpace="2rem"
            w="100%"
            responsive="576px"
            rItemSpace="1rem"
          >
            <InputField>
              <PrefixInputText
                as="div"
                className={errors.firstName ? "error" : ""}
              >
                <span className="prefix">Dr.</span>
                <InputText
                  required
                  placeholder="First Name"
                  type="text"
                  name="firstName"
                  defaultValue={formFieldsRef.current.firstName}
                />
              </PrefixInputText>
              {errors.firstName && (
                <ErrorMessage>{errors.firstName}</ErrorMessage>
              )}
            </InputField>
            <InputField>
              <InputText
                className={errors.lastName ? "error" : ""}
                required
                placeholder="Last Name"
                type="text"
                name="lastName"
                defaultValue={formFieldsRef.current.lastName}
              />
              {errors.lastName && (
                <ErrorMessage>{errors.lastName}</ErrorMessage>
              )}
            </InputField>
          </HStack>
          <VStack>
            <p>Gender</p>
            <HStack w="100%">
              <CustomRadio htmlFor="male">
                Male
                <input
                  required
                  id="male"
                  name="gender"
                  value="male"
                  type="radio"
                  defaultChecked={
                    formFieldsRef.current.gender === "male" ? true : null
                  }
                />
                <span
                  className={errors.gender ? "error checkmark" : "checkmark"}
                ></span>
              </CustomRadio>
              <CustomRadio htmlFor="female">
                Female
                <input
                  id="female"
                  name="gender"
                  value="female"
                  type="radio"
                  defaultChecked={
                    formFieldsRef.current.gender === "female" ? true : null
                  }
                />
                <span
                  className={errors.gender ? "error checkmark" : "checkmark"}
                ></span>
              </CustomRadio>
              <CustomRadio htmlFor="others">
                Others
                <input
                  id="others"
                  name="gender"
                  value="others"
                  type="radio"
                  defaultChecked={
                    formFieldsRef.current.gender === "others" ? true : null
                  }
                />
                <span
                  className={errors.gender ? "error checkmark" : "checkmark"}
                ></span>
              </CustomRadio>
            </HStack>
            {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
          </VStack>
          <InputField>
            <InputText
              className={errors.email ? "error" : ""}
              required
              placeholder="Email"
              type="email"
              name="email"
              defaultValue={formFieldsRef.current.email}
            />
            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </InputField>
          <InputField>
            <InputText
              className={errors.phone ? "error" : ""}
              required
              placeholder="Phone"
              type="phone"
              name="phone"
              defaultValue={formFieldsRef.current.phone}
            />
            {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
          </InputField>
          <InputField>
            <InputText
              className={errors.businessName ? "error" : ""}
              required
              placeholder="Business Name"
              type="text"
              name="businessName"
              defaultValue={formFieldsRef.current.businessName}
            />
            {errors.businessName && (
              <ErrorMessage>{errors.businessName}</ErrorMessage>
            )}
          </InputField>
          <InputField>
            <MultiSelectDropdown
              className={errors.services ? "error" : ""}
              services={services}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
            {errors.services && <ErrorMessage>{errors.services}</ErrorMessage>}
          </InputField>
          <HStack
            itemSpace="2rem"
            w="100%"
            responsive="576px"
            rItemSpace="1rem"
          >
            <InputField>
              <InputText
                className={errors.password ? "error" : ""}
                required
                placeholder="Password"
                type="password"
                name="password"
                defaultValue={formFieldsRef.current.password}
              />
              {errors.password && (
                <ErrorMessage>{errors.password}</ErrorMessage>
              )}
            </InputField>
            <InputField>
              <InputText
                className={errors.confirmPassword ? "error" : ""}
                required
                placeholder="Confirm Password"
                type="password"
                name="confirmPassword"
                defaultValue={formFieldsRef.current.confirmPassword}
              />
              {errors.confirmPassword && (
                <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
              )}
            </InputField>
          </HStack>
          <PrimaryButton
            className="btn btn-primary ml-auto"
            type="button"
            onClick={() => {
              handleDataForValidation();
            }}
          >
            Next <ArrowRight size={24} />
          </PrimaryButton>
        </VStack>
      </HStack>
    </>
  );
}

export default Step1;
