import { useContext } from "react";
import GlobalStyle from "./styledComponents/GlobalStyles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalContext } from "./globalComponents/context/GlobalContextApi";
import Axios from "axios";
import Snackbar from "./globalComponents/Snackbar";
import ErrorPage from "./globalComponents/ErrorPage";
import ProtectedRoute from "./globalComponents/ProtectedRoute";

// Doctor imports
import DoctorRegister from "./doctor/auth/Register";
import DoctorLogin from "./doctor/auth/Login";
import DoctorConfirm from "./doctor/auth/Verify";
import DoctorThankYou from "./doctor/auth/ThankYou";
import DoctorIndex from "./doctor/DoctorIndex";

// Patient imports
import PatientLogin from "./patient/auth/Login";
import PatientRegister from "./patient/auth/Register";
import Index from "./patient/pages/Index";
import PatientThankYou from "./patient/auth/ThankYou";
import PatientIndex from "./patient/PatientIndex";
import LandingPage from "./patient/pages/LandingPage";

// Axios.defaults.baseURL = "http://localhost:4000";
Axios.defaults.baseURL = "https://api.mendsmile.com";

function App() {
  const { user, registerAccess } = useContext(GlobalContext);

  console.log("App js useEffect");

  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          {/* Doctor Routes */}
          <Route path="/doctor/register" element={<DoctorRegister />} />
          <Route path="/doctor/activate/:id" element={<DoctorConfirm />} />
          <Route path="/doctor/login" element={<DoctorLogin />} />
          <Route path="/doctor/thankyou" element={<DoctorThankYou />} />
          <Route
            path="/doctor/*"
            element={<ProtectedRoute role="Doctor" component={DoctorIndex} />}
          />
          {/* Patient Routes */}
          <Route path="/" exact element={<Index />} />
          <Route path="/dentist/:slug" element={<LandingPage />} />
          <Route path="/patient/login" element={<PatientLogin />} />
          {!user && registerAccess && (
            <Route path="/patient/register" element={<PatientRegister />} />
          )}
          <Route path="/patient/thankyou" element={<PatientThankYou />} />
          <Route
            path="/patient/*"
            element={<ProtectedRoute role="Patient" component={PatientIndex} />}
          ></Route>
          {user && <Route path="*" element={<ErrorPage />} />}
        </Routes>
      </Router>
      <Snackbar />
    </>
  );
}

export default App;
