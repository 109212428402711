import React, { useContext, useState } from "react";
import Axios from "axios";
import {
  ErrorMessage,
  InputText,
  PrimaryButton,
} from "../../../styledComponents/GlobalElements";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "react-feather";
import { GlobalContext } from "../../../globalComponents/context/GlobalContextApi";

function EmailStep({ setComponent, doctorID, usernameRef }) {
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  const { setRegisterAccess, setSnackbarMessage } = useContext(GlobalContext); // context

  const validateUser = () => {
    Axios({
      method: "POST",
      data: {
        username: usernameRef.current.value,
      },
      withCredentials: true,
      url: "/patient/validateUser",
    }).then((res) => {
      if (res.data.status) {
        // -> password -> step2 register -> book appointment
        setRegisterAccess(true);
        setComponent("pass");
        usernameRef.current = res.data.result.Email;
      } else if (doctorID) {
        // -> step1 register -> register & book appointment
        setRegisterAccess(true);
        let email;
        let phone;
        if (/^.*@.*$/.test(usernameRef.current.value)) {
          email = usernameRef.current.value;
        } else {
          phone = usernameRef.current.value;
        }
        navigate(`/patient/register`, {
          state: {
            doctorID,
            email,
            phone,
            userExists: false,
          },
        });
      } else {
        setSnackbarMessage("User does not exist.");
      }
    });
  };
  return (
    <>
      <h1 className="text-center">Book Appointment</h1>
      {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      <InputText
        type="email"
        placeholder="Email or Phone"
        ref={usernameRef}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            console.log("key up");
            e.preventDefault();
            if (usernameRef.current.value) {
              validateUser();
            } else {
              setErrorMsg("Enter email or phone");
            }
          }
        }}
      />
      <PrimaryButton
        onClick={() => {
          if (usernameRef.current.value) {
            validateUser();
          } else {
            setErrorMsg("Enter email or phone");
          }
        }}
      >
        Next <ArrowRight size={20} />
      </PrimaryButton>
    </>
  );
}

export default EmailStep;
