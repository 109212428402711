import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Gallery from "./Gallery";
import { VStack, HStack, Card } from "../styledComponents/GlobalElements";
import { GlobalContext } from "./context/GlobalContextApi";

const RowCols = styled(HStack)`
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  & > div {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: 576px) {
      width: 50%;
    }
    @media (min-width: 768px) {
      width: 33.3333%;
    }
    @media (min-width: 992px) {
      width: 25%;
    }
    @media (min-width: 1400px) {
      width: 16.6666%;
    }
  }
`;

function SelectCaseImages({ mediaIDs }) {
  const [services, setServices] = useState([]); // Service object from db.
  const [images, setImages] = useState([]);
  const [serviceName, setServiceName] = useState([]);
  //   const [loading, setLoading] = useState(true);

  const { imgBaseUrl } = useContext(GlobalContext);

  const fetchImages = (serviceID) => {
    Axios({
      method: "POST",
      data: { serviceID },
      withCredentials: true,
      url: "/doctor/getCaseImages",
    }).then((res) => {
      if (res.data.status) {
        setImages(res.data.result);
      } else {
        setImages([]);
      }
    });
  };
  useEffect(() => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/doctor/doctorServices",
    }).then((res) => {
      if (res.data.status) {
        setServices(res.data.result);
      }
    });
  }, []);
  return (
    <VStack padding="1.5rem" itemSpace="1.5rem">
      <RowCols>
        {services.map((service, i) => {
          return (
            <div key={i} className="col">
              <Card
                onClick={() => {
                  // setLoading(true);
                  setServiceName({
                    serviceName: service.Name,
                    serviceID: service.Service_ID,
                  });
                  fetchImages(service.Service_ID);
                }}
              >
                <div className="card-header">{service.Name}</div>
                <img
                  src={`${imgBaseUrl}/service_images/i_${service.Service_ID}_1.jpeg`}
                  alt={service.Name}
                />
              </Card>
            </div>
          );
        })}
      </RowCols>
      {images && serviceName.serviceName ? (
        <Gallery
          images={images}
          mediaIDs={mediaIDs}
          serviceName={serviceName.serviceName}
          services={serviceName.serviceID}
          fetchImages={fetchImages}
          selectCaseImages={true}
        />
      ) : null}
    </VStack>
  );
}

export default SelectCaseImages;
