import React, { useState, useCallback, useRef, useEffect } from "react";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";
import ReactCrop from "react-image-crop";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;
const ReactCropStyled = styled(ReactCrop)`
  max-height: 100%;
`;

export default function ImageCrop({ propImg, croppedImage, aspect }) {
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 30,
    aspect: aspect,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    canvas.toBlob(
      async (blob) => {
        if (!blob) {
          return;
        }
        const file = new File([blob], "profile-picture.png", {
          type: "image/png",
        });
        croppedImage.current = file;
      },
      "image/png",
      1
    );
  }, [completedCrop, croppedImage]);

  return (
    <Wrapper>
      <ReactCropStyled
        src={propImg}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => setCompletedCrop(c)}
        ruleOfThirds
      />
    </Wrapper>
  );
}
