import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { Camera } from "react-feather";
import styled from "styled-components";
import { GlobalContext } from "./context/GlobalContextApi";
import { HStack } from "../styledComponents/GlobalElements";

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  p,
  a,
  h4 {
    color: white;
  }
  h4 {
    padding: 1rem;
    border-bottom: 1px solid white;
  }
`;
const GalleryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 1rem;
  padding: 1rem;
  span {
    padding: 1rem;
    color: white;
    a {
      color: grey;
    }
  }
`;
const GalleryImgWrapper = styled.div`
  display: grid;
  place-items: center;
  &::before {
    // for apsect ratio
    content: "";
    display: block;
    padding-bottom: 100%;
    grid-area: 1 / 1 / 2 / 2;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 2px solid white;
    grid-area: 1 / 1 / 2 / 2;
  }
`;
const GalleryUploadWrapper = styled.div`
  display: flex;
  justify-content: center;
  border: 2px solid white;
  padding: 1rem;
  display: grid;
  place-items: center;
  &::before {
    // for apsect ratio
    content: "";
    display: block;
    padding-bottom: 100%;
    grid-area: 1 / 1 / 2 / 2;
  }
`;
const UploadImage = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  grid-area: 1 / 1 / 2 / 2;
  input {
    display: none;
  }
`;

function Gallery({
  images,
  mediaIDs,
  serviceName,
  services,
  fetchImages,
  selectCaseImages,
}) {
  const [update, setupdate] = useState(false);
  const { imgBaseUrl, setSnackbarMessage } = useContext(GlobalContext);

  const handleUpload = (event) => {
    const files = event.target.files;
    var formData = new FormData();
    formData.append("serviceID", services);
    for (let i = 0; i < files.length; i++) {
      formData.append("caseImage", files[i]);
    }
    Axios({
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      withCredentials: true,
      url: "/doctor/uploadCaseImages",
    }).then((res) => {
      if (res.data.status) {
        setSnackbarMessage("Case Images uploaded");
        fetchImages(services);
      }
    });
  };
  return (
    <GalleryContainer>
      <h4>{serviceName}</h4>
      {images.length ? (
        <GalleryWrapper>
          {images.length &&
            images.map((image, i) => {
              let mediaIndex = mediaIDs.findIndex(
                (id) => id === image.Media_ID
              );
              return (
                <GalleryImgWrapper
                  key={image.Media_ID}
                  className={mediaIDs && mediaIndex !== -1 ? "selected" : ""}
                  onClick={() => {
                    if (mediaIDs) {
                      if (mediaIndex === -1) {
                        mediaIDs.push(image.Media_ID);
                        setupdate(!update);
                      } else {
                        setupdate(!update);
                        mediaIDs.splice(mediaIndex, 1);
                      }
                    }
                  }}
                >
                  <img
                    key={i}
                    src={`${imgBaseUrl}/case_images/${image.Path}`}
                    alt="CaseImage"
                  />
                </GalleryImgWrapper>
              );
            })}
          {!selectCaseImages && (
            <GalleryUploadWrapper>
              <UploadImage htmlFor="caseImage" className="upload-label">
                <Camera size={36} strokeWidth="1.5" color="white" />
                <span>Upload Case Images</span>
                <input
                  type="file"
                  multiple
                  name="caseImage"
                  onChange={handleUpload}
                  id="caseImage"
                />
              </UploadImage>
            </GalleryUploadWrapper>
          )}
        </GalleryWrapper>
      ) : (
        <HStack padding="1rem" responsive="576px" w="100%">
          <p>
            No Case Images Added.{" "}
            <Link to="/doctor/caseImages">Click here</Link> to add images.
          </p>
        </HStack>
      )}
    </GalleryContainer>
  );
}

export default Gallery;
