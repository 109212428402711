import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    html, body{
      height: 100%;
    }
    body{
      font-family: 'Poppins', sans-serif;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
        border: none;
        outline: none;
        background: none;
    }
    #root{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    h1{
        font-family: 'Playfair Display', sans-serif;
        font-size: 36px;
    }
    h2{
        font-family: 'Playfair Display', sans-serif;
        font-size: 24px;
    }
    button, input, optgroup, select, textarea {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }
    // width
    .m-w-1140{
        max-width: 1140px;
        margin: 0 auto !important;
    }
    // Background color
    .bg-lightGrey{
        background-color: #fafafa;
    }
    .bg-grey{
        background-color: #f5f5f5;
    }
    .bg-black{
        background-color: black;
    }
    // Text color 
    .c-white{
        color: white;
    }
    // Text alignment
    .text-center{
        text-align: center;
    }
    // Margin auto
    .ml-auto{
        margin-left: auto;
    }
    // Overflow
    .of-auto{
        overflow: auto;
    }


    // login page
    .login-box{
        max-width: 570px;
        padding: 3.75rem 2.5rem;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #fafafa;

        small {
            margin-top: 10px;
            margin-bottom: 0px !important;
            margin-right: auto !important;
            font-weight: 500;
        }
    }

    // index page
    .index-box{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3.75rem 2.5rem;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #fafafa;
        width: 100%;
        max-width: 570px;

        select {
            width: 100%;
            background-color: #fff;
            padding: 10px;
            border-radius: 5px;
            border: 2px solid black;
            /* margin-top: 1rem; */
            margin-bottom: 1rem;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 50%;
        }

        // Extra small devices (portrait phones, less than 576px)
        @media (max-width: 575.98px) {
            padding: 2.75rem 1.5rem;
        }
    }

    // Gallery
    .selected{
        outline: 5px solid green;
    }

    `;
export default GlobalStyle;
