import React, { useState, useRef, useContext, useEffect } from "react";
import Axios from "axios";
import { Camera } from "react-feather";
import {
  Heading,
  ProfilePicture,
  VStack,
  Line,
  CustomFileUpload,
} from "../../styledComponents/GlobalElements";
import ImageCrop from "../../globalComponents/ImageCrop";
import Modal from "../../globalComponents/Modal";
import { GlobalContext } from "../../globalComponents/context/GlobalContextApi";
import styled from "styled-components";

export const LogoBox = styled(VStack)`
  border: 2px solid black;
  border-radius: 5px;
`;

function Logo() {
  const croppedImageRef = useRef();

  const { imgBaseUrl, setSnackbarMessage } = useContext(GlobalContext);

  const [logo, setLogo] = useState("");

  const modalChildrenRef = useRef();
  const modalSaveHandlerRef = useRef();
  const modalHeaderRef = useRef();

  const [modalActive, setModalActive] = useState(false);
  const modalToggleHandler = () => {
    setModalActive(!modalActive);
  };

  const updateLogo = () => {
    const formData = new FormData();
    formData.append("logo", croppedImageRef.current);
    setModalActive(false);
    Axios({
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
      url: "/doctor/uploadLogo",
    }).then((res) => {
      if (res.data.status) {
        fetchLogo();
        setModalActive(false);
        setSnackbarMessage("Logo updated");
      }
    });
  };

  const fetchLogo = () => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/doctor/getLogo",
    }).then((res) => {
      if (res.data.status) {
        setLogo(res.data.result.Logo);
        console.log(res.data.result);
      }
    });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        modalChildrenRef.current = (
          <ImageCrop
            propImg={reader.result}
            croppedImage={croppedImageRef}
            // aspect="null"
          />
        );
        modalHeaderRef.current = "Upload Logo";
        modalSaveHandlerRef.current = updateLogo;
        modalToggleHandler();
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    fetchLogo();
  }, []);

  return (
    <VStack
      as="section"
      margin="1.5rem 0px"
      padding="1.5rem"
      itemSpace="1.5rem"
      className="m-w-1140"
    >
      <h1>Logo</h1>
      <LogoBox
        as="form"
        id="updateInfo-form"
        padding="1rem"
        align="center"
        justify="center"
        itemSpace="1rem"
      >
        {logo ? (
          <ProfilePicture
            src={`${imgBaseUrl}/logo_images/${logo}`}
            alt="logo"
          />
        ) : (
          <Heading>No Image Uploaded</Heading>
        )}
        <Line />
        <CustomFileUpload>
          <label htmlFor="actual-btn">
            <span>Upload Logo</span>
            <Camera />
            <input
              type="file"
              accept="image/*"
              name="logo"
              id="actual-btn"
              onChange={onSelectFile}
              hidden
            />
          </label>
        </CustomFileUpload>
      </LogoBox>
      {modalActive && (
        <Modal
          close={modalToggleHandler}
          header={modalHeaderRef.current}
          saveHandler={modalSaveHandlerRef.current}
        >
          {modalChildrenRef.current}
        </Modal>
      )}
    </VStack>
  );
}

export default Logo;
