import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import GlobalContextApi from "./globalComponents/context/GlobalContextApi";

ReactDOM.render(
  <React.StrictMode>
    <GlobalContextApi>
      <App />
    </GlobalContextApi>
  </React.StrictMode>,
  document.getElementById("root")
);
