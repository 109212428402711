import React, { useContext } from "react";
import { DropdownContainer } from "./DropdownElements";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../../globalComponents/context/GlobalContextApi";

function Dropdown({ dropdownActive }) {
  const navigate = useNavigate();

  const { checkUserLogin } = useContext(GlobalContext);

  const logoutUser = () => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/logout",
    }).then((res) => {
      if (res.data.status) {
        document.cookie =
          "is_logged_in=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        checkUserLogin();
        navigate("/doctor/login");
      }
    });
  };
  return (
    <DropdownContainer dropdownActive={dropdownActive}>
      <li>
        <Link to="/doctor/testimonial">Testimonial</Link>
      </li>
      <li>
        <Link to="/doctor/logo">Logo</Link>
      </li>
      <li>
        <Link to="/doctor/account">Account</Link>
      </li>
      <li onClick={logoutUser}>Logout</li>
    </DropdownContainer>
  );
}

export default Dropdown;
