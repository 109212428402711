import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Header,
  OutlineButton,
  VStack,
  Heading,
  Line,
  InputFile,
} from "../../styledComponents/GlobalElements";
import { GlobalContext } from "../../globalComponents/context/GlobalContextApi";

function IntroVideo() {
  const [isSuccess, setSuccess] = useState(null);
  const [introVideo, setVideo] = useState("");

  const { setSnackbarMessage } = useContext(GlobalContext);

  const handleChange = (e) => {
    document.getElementById("file-chosen").innerHTML = e.target.files[0].name;
  };
  const fetchVideo = () => {
    axios({
      method: "GET",
      withCredentials: true,
      url: "/doctor/introVideo",
    }).then((res) => {
      if (res.data.status) {
        setVideo(res.data.result.Intro_Video);
      } else {
        setVideo("");
      }
    });
  };
  const submit = async (e) => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    axios({
      method: "POST",
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
      url: "/doctor/uploadIntroVideo",
    }).then((res) => {
      if (res.data.status) {
        setSuccess("Video upload successfully");
        setSnackbarMessage("Video upload successfully");
        fetchVideo();
      }
    });
  };
  useEffect(() => {
    fetchVideo();
  }, []);
  return (
    <VStack
      as="section"
      margin="1.5rem 0px"
      padding="1.5rem"
      itemSpace="1.5rem"
      className="m-w-1140"
    >
      <Header>
        <h1>Dashboard</h1>
        <OutlineButton>Tech Support</OutlineButton>
      </Header>
      <VStack align="center">
        {introVideo ? (
          <video
            controls
            // src={`http://localhost:4000/images/uploads/intro_videos/${introVideo}`}
            src={`https://api.mendsmile.com/images/uploads/intro_videos/${introVideo}`}
          />
        ) : (
          <Heading>No Video Uploaded</Heading>
        )}
      </VStack>
      <Line></Line>
      <form onSubmit={submit}>
        {isSuccess !== null ? <h4> {isSuccess} </h4> : null}
        <input
          type="file"
          name="intro_video"
          id="actual-btn"
          onChange={handleChange}
          hidden
        />
        <InputFile>
          <label htmlFor="actual-btn">Choose File</label>
          <span id="file-chosen">No file chosen</span>
          <button type="submit">Upload</button>
        </InputFile>
      </form>
    </VStack>
  );
}

export default IntroVideo;
