import React, { useState, useEffect, useCallback, useRef } from "react";
import Axios from "axios";
import { ChevronDown, Search } from "react-feather";
import {
  // Filter,
  Header,
  HStack,
  OutlineButton,
  SearchBar,
  SmallButton,
  PrimaryButton,
  VStack,
} from "../../styledComponents/GlobalElements";
import { Table } from "../../styledComponents/TableElements";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const [appointmentList, setAppointmentList] = useState([]);
  const [paginationLength, setPaginationLength] = useState(0); // total no of appointments specific to doctor. length
  const [limit, setLimit] = useState(10);

  const sortingRef = useRef();

  const redirectViewAppointment = ({ id }) => {
    navigate(`/patient/viewAppointment`, { state: { appointmentID: id } });
  };

  const fetchAppointmentList = useCallback(
    (sorting) => {
      Axios({
        method: "POST",
        data: { limit, sorting },
        withCredentials: true,
        url: "/patient/appointmentList",
      }).then((res) => {
        if (res.data.status) {
          setAppointmentList(res.data.result);
          setPaginationLength(res.data.length);
        } else {
          setAppointmentList([]);
          setPaginationLength(0);
        }
      });
    },
    [limit]
  );

  useEffect(() => {
    fetchAppointmentList();
  }, [limit, fetchAppointmentList]);

  return (
    <VStack
      as="section"
      margin="1.5rem 0px"
      padding="1.5rem"
      itemSpace="1.5rem"
      className="m-w-1140"
    >
      <Header>
        <h1>Dashboard</h1>
        <OutlineButton>Tech Support</OutlineButton>
      </Header>
      <HStack responsive="576px" rItemSpace="1.5rem">
        <SearchBar htmlFor="dashboard-search">
          <input
            type="text"
            placeholder="Search"
            id="dashboard-search"
            onChange={(e) => {
              sortingRef.current = {
                ...sortingRef.current,
                ...{ searchString: e.target.value },
              };
              fetchAppointmentList(sortingRef.current);
            }}
          />
          <Search size={24} />
        </SearchBar>
        {/* <Filter>
          <span>Lead Status</span>
          <select
            onChange={(e) => {
              sortingRef.current = {
                ...sortingRef.current,
                ...{ sortBy: e.target.value },
              };
              fetchAppointmentList(sortingRef.current);
            }}
            defaultValue=""
          >
            <option value="">All Active</option>
            <optgroup label="Active">
              <option value="new">New</option>
              <option value="inContact">In Contact</option>
              <option value="scheduled">Scheduled</option>
            </optgroup>

            <optgroup label="Archived">
              <option value="noResponse">No Response</option>
              <option value="notInterested">Not Interested</option>
            </optgroup>
          </select>
        </Filter> */}
      </HStack>
      <VStack align="center" justify="center">
        <Table>
          <thead>
            <tr>
              <th scope="col">Doctor Name</th>
              <th scope="col">Email Address</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Requested Date</th>
              <th scope="col">Video</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {appointmentList.length > 0 ? (
              appointmentList.map((patient, i) => {
                return (
                  <tr key={i}>
                    <td data-label="Patient Name">
                      <strong>{patient.FullName}</strong>
                    </td>
                    <td data-label="Email Address">{patient.Email}</td>
                    <td data-label="Phone Number">{patient.Phone}</td>
                    <td data-label="Requested Date">
                      {new Date(patient.Datetime).toLocaleDateString("en-US")}
                    </td>
                    <td data-label="Video">
                      {patient.Doctor_Video ? "Sent" : "Not Sent"}
                    </td>
                    <td>
                      <SmallButton
                        onClick={() =>
                          redirectViewAppointment({
                            id: patient.Appointment_ID,
                          })
                        }
                      >
                        View Profile
                      </SmallButton>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">
                  <strong>No Appointments</strong>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {limit >= paginationLength ? null : (
          <PrimaryButton
            disabled={limit >= paginationLength ? true : false}
            onClick={() => {
              if (limit < paginationLength) {
                setLimit(limit + 10);
              }
            }}
          >
            View More <ChevronDown size={18} />
          </PrimaryButton>
        )}
      </VStack>
    </VStack>
  );
}

export default Dashboard;
