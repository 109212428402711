import React from "react";
import { VStack } from "../../styledComponents/GlobalElements";

function ThankYou() {
  return (
    <VStack w="100%" h="100%" align="center" justify="center" padding="1rem">
      <p>Thank you for booking appointment</p>
      <h1 className="text-center">Doctor will get back to you soon.</h1>
    </VStack>
  );
}

export default ThankYou;
