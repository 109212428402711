import React, { useRef, useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { VStack } from "../../styledComponents/GlobalElements";
import EmailStep from "./loginComponents/EmailStep";
import PasswordStep from "./loginComponents/PasswordStep";

function Login() {
  const [component, setComponent] = useState("email");
  const [businessName, setBusinessName] = useState("");

  const usernameRef = useRef();

  const doctorIDRef = useRef(
    new URLSearchParams(window.location.search).get("doctorID")
  );

  const fetchDoctorDetails = useCallback(() => {
    Axios({
      method: "GET",
      params: { Doctor_ID: doctorIDRef.current },
      withCredentials: true,
      url: "/patient/doctorDetails",
    }).then((res) => {
      if (res.data.status) {
        setBusinessName(res.data.result.Business_Name);
      }
    });
  }, []);

  useEffect(() => {
    if (doctorIDRef.current) {
      fetchDoctorDetails();
    }
    return () => {};
  }, [fetchDoctorDetails]);

  return (
    <VStack w="100%" h="100%" align="center" justify="center">
      <VStack align="center" justify="center" className="login-box">
        <p>Welcome to {businessName ? businessName : "Mendsmile"}</p>
        <VStack itemSpace="2rem" align="center" justify="center" as="">
          {component === "email" ? (
            <EmailStep
              setComponent={setComponent}
              doctorID={doctorIDRef.current}
              usernameRef={usernameRef}
            />
          ) : (
            <PasswordStep
              doctorID={doctorIDRef.current}
              usernameRef={usernameRef}
            />
          )}
        </VStack>
      </VStack>
    </VStack>
  );
}

export default Login;
