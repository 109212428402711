import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import Axios from "axios";
import {
  Header,
  PrimaryButton,
  DoctorVideo,
  Line,
  Card,
  VStack,
  HStack,
  StatusButton,
  ListGroup,
  ListGroupItem,
  OutlineLink,
} from "../../styledComponents/GlobalElements";
import { Link, useLocation } from "react-router-dom";
import YourRecommendation from "../../globalComponents/viewAppointmentComponents/YourRecommendation";
import SelectCaseDisplay from "../../globalComponents/viewAppointmentComponents/SelectCaseDisplay";
import SelectCaseImages from "../../globalComponents/SelectCaseImages";
import Modal from "../../globalComponents/Modal";
import { GlobalContext } from "../../globalComponents/context/GlobalContextApi";

function ViewAppointment() {
  const location = useLocation();
  const appointmentIDRef = useRef(location.state?.appointmentID);
  const mediaIDsRef = useRef([]);
  const suggestedTreatmentRef = useRef("");
  const requiredAppointmentsRef = useRef([]);
  const investmentRef = useRef([]);
  const updateValuesRef = useRef({});

  const [appointment, setAppointment] = useState({});
  const [patientsPics, setPatientsPics] = useState({});
  const [caseMedia, setCaseMedia] = useState([]);

  const { imgBaseUrl, setSnackbarMessage } = useContext(GlobalContext);

  const [modalActive, setModalActive] = useState(false);

  const modalChildrenRef = useRef();
  const modalSaveHandlerRef = useRef();
  const modalHeaderRef = useRef();

  const updateCaseMedia = () => {
    Axios({
      method: "POST",
      data: {
        appointmentID: appointmentIDRef.current,
        mediaID: JSON.stringify(mediaIDsRef.current),
      },
      withCredentials: true,
      url: "/doctor/updateCaseMedia",
    }).then((res) => {
      if (res.data.status) {
        setModalActive(false);
        fetchCaseMedia();
        setSnackbarMessage("Case Media updated");
      }
    });
  };
  const updateRecommendation = () => {
    // console.log("update updateValuesRef :", updateValuesRef.current);
    Axios({
      method: "POST",
      data: {
        appointmentID: appointmentIDRef.current,
        suggestedTreatment: updateValuesRef.current.suggestedTreatment,
        requiredAppointments: JSON.stringify(
          updateValuesRef.current.requiredAppointments
        ),
        investment: JSON.stringify(updateValuesRef.current.investment),
      },
      withCredentials: true,
      url: "/doctor/updateRecommendation",
    }).then((res) => {
      if (res.data.status) {
        requiredAppointmentsRef.current =
          updateValuesRef.current.requiredAppointments;
        investmentRef.current = updateValuesRef.current.investment;
        suggestedTreatmentRef.current =
          updateValuesRef.current.suggestedTreatment;
        setSnackbarMessage("Recommendations updated");
        setModalActive(false);
        // console.log("suggestedTreatmentRef.current :", updateValuesRef.current);
      }
    });
  };
  const updateStatus = (e) => {
    Axios({
      method: "POST",
      data: {
        appointmentID: appointmentIDRef.current,
        status: e.target.value,
      },
      withCredentials: true,
      url: "/doctor/updateStatus",
    }).then((res) => {
      if (res.data.status) {
        setModalActive(false);
        setSnackbarMessage("Status updated");
      }
    });
  };

  const fetchCaseMedia = () => {
    Axios({
      method: "POST",
      data: { mediaID: mediaIDsRef.current.toString() },
      withCredentials: true,
      url: "/doctor/getCaseMedia",
    }).then((res) => {
      if (res.data.status) {
        setCaseMedia(res.data.result);
      }
    });
  };

  const fetchAppointmentDetails = useCallback(() => {
    Axios({
      method: "POST",
      data: { appointmentID: appointmentIDRef.current },
      withCredentials: true,
      url: "/doctor/appointmentDetails",
    }).then((res) => {
      if (res.data.status) {
        mediaIDsRef.current = JSON.parse(res.data.result.Case_Media) || [];
        suggestedTreatmentRef.current =
          res.data.result.Suggested_Treatment || "";
        requiredAppointmentsRef.current =
          JSON.parse(res.data.result.Required_Appointments) || [];
        investmentRef.current = JSON.parse(res.data.result.Investment) || [];

        setPatientsPics(JSON.parse(res.data.result.Patient_Selfies));
        setAppointment(res.data.result);
        fetchCaseMedia();
      }
    });
  }, []);

  const modalToggleHandler = () => {
    setModalActive(!modalActive);
  };

  useEffect(() => {
    fetchAppointmentDetails();
  }, [fetchAppointmentDetails]);

  return (
    <>
      <VStack as="section" className="m-w-1140">
        <VStack padding="1.5rem 1.5rem 0 1.5rem">
          <Header>
            <h1>{appointment.Patient_Name}</h1>
            <StatusButton name="" id="updateStatus" onChange={updateStatus}>
              <optgroup label="Active">
                <option
                  selected={appointment.Status === "new" ? true : null}
                  value="new"
                >
                  New
                </option>
                <option
                  selected={appointment.Status === "inContact" ? true : null}
                  value="inContact"
                >
                  In Contact
                </option>
                <option
                  selected={appointment.Status === "scheduled" ? true : null}
                  value="scheduled"
                >
                  Scheduled
                </option>
              </optgroup>

              <optgroup label="Archived">
                <option
                  selected={appointment.Status === "noResponse" ? true : null}
                  value="noResponse"
                >
                  No Response
                </option>
                <option
                  selected={
                    appointment.Status === "notInterested" ? true : null
                  }
                  value="notInterested"
                >
                  Not Interested
                </option>
              </optgroup>
            </StatusButton>
          </Header>
        </VStack>
        {appointment.Doctor_Video && (
          <VStack
            align="center"
            padding="1.5rem 1.5rem 0 1.5rem"
            itemSpace="1.5rem"
          >
            <DoctorVideo
              controls
              src={`${imgBaseUrl}/recorded_videos/${appointment.Doctor_Video}`}
            ></DoctorVideo>
            <Line></Line>
          </VStack>
        )}
        <HStack
          itemSpace="1.5rem"
          padding="1.5rem"
          responsive="576px"
          rItemSpace="1.5rem"
          w="100%"
        >
          <VStack w="50%" responsive="576px" rw="100%">
            <Card>
              <div className="card-header">Selfie</div>
              <img
                src={`${imgBaseUrl}/patient_pictures/${patientsPics.selfie}`}
                alt="Selfie"
              />
            </Card>
          </VStack>
          <VStack w="50%" responsive="576px" rw="100%">
            <Card>
              <div className="card-header">Close-up</div>
              <img
                src={`${imgBaseUrl}/patient_pictures/${patientsPics.closeup}`}
                alt="Close-up"
              />
            </Card>
          </VStack>
        </HStack>
        <VStack padding="0 1.5rem">
          <Line></Line>
        </VStack>
        <HStack
          itemSpace="1.5rem"
          padding="1.5rem"
          responsive="576px"
          rItemSpace="1.5rem"
        >
          <VStack w="50%" responsive="576px" rw="100%">
            <ListGroup>
              <ListGroupItem>
                <h4>Email Address</h4>
                <p>{appointment.Patient_Email}</p>
              </ListGroupItem>
              <ListGroupItem>
                <h4>Phone Number</h4>
                <p>{appointment.Patient_Phone}</p>
              </ListGroupItem>
              <ListGroupItem>
                <h4>Gender</h4>
                <p>{appointment.Patient_Gender}</p>
              </ListGroupItem>
              <ListGroupItem>
                <h4>Postal Code</h4>
                <p>{appointment.Patient_Postal_Code}</p>
              </ListGroupItem>
              <ListGroupItem>
                <h4>What would you like to improve about your simle?</h4>
                <p>
                  {appointment.Improve &&
                    JSON.parse(appointment.Improve)
                      .toString()
                      .replace(/,(?=[^\s])/g, ", ")}
                </p>
              </ListGroupItem>
            </ListGroup>
          </VStack>
          <VStack w="50%" responsive="576px" rw="100%">
            <ListGroup>
              <ListGroupItem>
                <h4>Are you missing a tooth?</h4>
                <p>{appointment.Missing_Teeth}</p>
              </ListGroupItem>
              <ListGroupItem>
                <h4>Interested Services</h4>
                <p>{appointment.Services && appointment.Services.toString()}</p>
              </ListGroupItem>
              <ListGroupItem>
                <h4>What would you like to change about your simle? </h4>
                <p>{appointment.Concern}</p>
              </ListGroupItem>
              <ListGroupItem>
                <h4>Referral Source</h4>
                <p>{appointment.Referral_Source}</p>
              </ListGroupItem>
            </ListGroup>
          </VStack>
        </HStack>
        <VStack padding="0 1.5rem">
          <Line></Line>
        </VStack>
        <VStack padding="1.5rem" itemSpace="1.5rem">
          <VStack align="center">
            <small>Case Studies</small>
            <h2>Smile Makeovers</h2>
          </VStack>
          <SelectCaseDisplay images={caseMedia} />
          {!appointment.Doctor_Video && (
            <VStack align="center">
              <PrimaryButton
                onClick={() => {
                  modalChildrenRef.current = (
                    <SelectCaseImages mediaIDs={mediaIDsRef.current} />
                  );
                  modalHeaderRef.current = "Case Images";
                  modalSaveHandlerRef.current = updateCaseMedia;
                  modalToggleHandler();
                }}
              >
                {caseMedia.length > 0
                  ? "Edit Shared Cases"
                  : "Select Cases to Share"}
              </PrimaryButton>
            </VStack>
          )}
        </VStack>
        <VStack padding="0 1.5rem">
          <Line></Line>
        </VStack>
        <VStack padding="1.5rem" itemSpace="1.5rem">
          <VStack align="center">
            <small>Alternate Text</small>
            <h2>Recommendations</h2>
          </VStack>
          {(suggestedTreatmentRef.current ||
            requiredAppointmentsRef.current ||
            investmentRef.current) && (
            <YourRecommendation
              edit={false}
              recommendations={{
                suggestedTreatment: suggestedTreatmentRef.current,
                requiredAppointments: requiredAppointmentsRef.current,
                investment: investmentRef.current,
              }}
            />
          )}
          {!appointment.Doctor_Video && (
            <VStack align="center">
              <PrimaryButton
                onClick={() => {
                  updateValuesRef.current = {
                    suggestedTreatment: JSON.parse(
                      JSON.stringify(suggestedTreatmentRef.current)
                    ),
                    requiredAppointments: JSON.parse(
                      JSON.stringify(requiredAppointmentsRef.current)
                    ),
                    investment: JSON.parse(
                      JSON.stringify(investmentRef.current)
                    ),
                  };
                  modalChildrenRef.current = (
                    <YourRecommendation
                      edit={true}
                      recommendations={{
                        suggestedTreatment: suggestedTreatmentRef.current,
                        requiredAppointments: requiredAppointmentsRef.current,
                        investment: investmentRef.current,
                      }}
                      updateValues={updateValuesRef.current}
                    />
                  );
                  modalHeaderRef.current = "Recommendations";
                  modalSaveHandlerRef.current = updateRecommendation;
                  modalToggleHandler();
                }}
              >
                {suggestedTreatmentRef.current ||
                requiredAppointmentsRef.current ||
                investmentRef.current
                  ? "Edit Your Recommendation"
                  : "Your Recommendation"}
              </PrimaryButton>
            </VStack>
          )}
        </VStack>
        <VStack padding="0 1.5rem">
          <Line></Line>
        </VStack>
        {appointment.Doctor_Video === null ? (
          <VStack padding="1.5rem" itemSpace="1.5rem">
            <VStack align="center">
              <small>Let's Talk</small>
              <h2>Virtual Consult</h2>
            </VStack>
            <VStack align="center">
              {appointment.Doctor_Picture && appointment.Intro_Summary ? (
                <OutlineLink
                  to="/doctor/recordingPage"
                  state={{
                    appointment,
                    patientsPics,
                    suggestedTreatment: suggestedTreatmentRef.current,
                    requiredAppointments: requiredAppointmentsRef.current,
                    investment: investmentRef.current,
                    caseMedia: caseMedia,
                  }}
                >
                  View Patient Landing Page for Recording
                </OutlineLink>
              ) : (
                <p>
                  {"Complete your "}
                  <Link to="/doctor/account">profile</Link>
                  {" to respond to your patients."}
                </p>
              )}
            </VStack>
          </VStack>
        ) : (
          <VStack padding="1.5rem" itemSpace="1.5rem">
            <VStack align="center">
              <small>Let's Meet</small>
              <h2>Schedule Appointment</h2>
            </VStack>
            <VStack align="center">
              {appointment.Requested_Date ? (
                <>
                  <p>
                    Patient requested appointment for
                    {appointment.Requested_Date}
                  </p>
                  <OutlineLink to="#">Schedule Appointment</OutlineLink>
                </>
              ) : (
                <p>Waiting for patient to request an appointment</p>
              )}
            </VStack>
          </VStack>
        )}
      </VStack>
      {modalActive && (
        <Modal
          close={modalToggleHandler}
          header={modalHeaderRef.current}
          saveHandler={modalSaveHandlerRef.current}
        >
          {modalChildrenRef.current}
        </Modal>
      )}
    </>
  );
}

export default ViewAppointment;
