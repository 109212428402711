import styled from "styled-components";

export const DropdownContainer = styled.ul`
  position: absolute;
  z-index: 1000;
  min-width: 10rem;
  margin: 0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  display: ${({ dropdownActive }) => (dropdownActive ? "block" : "none")};
  top: 100%;
  right: 0;
  margin-top: 0.125rem;
  color: black;
  overflow: hidden;

  & > li {
    padding: 10px 12px;
    &:hover {
      background-color: #cccccc;
    }
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    position: relative;
    top: auto;
    margin: 18px 0px;
  }
`;
