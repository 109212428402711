import Axios from "axios";
import React, { useCallback, useRef, useState, useEffect } from "react";
import {
  HStack,
  InputText,
  Line,
  OrderedList,
  OutlineButton,
  Textarea,
  VStack,
  PrimaryButton,
  InputField,
} from "../../styledComponents/GlobalElements";

function Testimonial() {
  const [testimonails, setTestimonails] = useState([]);
  const [editActive, setEditActive] = useState(false);

  const editIDRef = useRef();

  const getTestimonial = useCallback(() => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/doctor/getTestimonials",
    }).then((res) => {
      if (res.data.status) {
        setTestimonails(res.data.result);
      }
    });
  }, []);

  const addTestimonial = useCallback(
    (e) => {
      e.preventDefault();
      var data = new FormData(document.getElementById("add-form"));
      var formLoopData = {};
      for (var [key, value] of data) {
        formLoopData[key] = value;
      }
      Axios({
        method: "POST",
        data: formLoopData,
        withCredentials: true,
        url: "/doctor/addTestimonial",
      }).then((res) => {
        if (res.data.status) {
          getTestimonial();
        }
      });
    },
    [getTestimonial]
  );

  const updateTestimonial = useCallback(
    (e) => {
      e.preventDefault();
      var data = new FormData(document.getElementById("update-form"));
      var formLoopData = {};
      for (var [key, value] of data) {
        formLoopData[key] = value;
      }
      formLoopData["testimonialID"] = editIDRef.current;
      Axios({
        method: "POST",
        data: formLoopData,
        withCredentials: true,
        url: "/doctor/updateTestimonial",
      }).then((res) => {
        if (res.data.status) {
          setEditActive(false);
          getTestimonial();
        }
      });
    },
    [getTestimonial]
  );

  useEffect(() => {
    getTestimonial();
  }, [getTestimonial]);

  return (
    <VStack padding="3rem" itemSpace="2rem">
      <VStack>
        <h2>Add Testimonial</h2>
        <HStack
          as="form"
          onSubmit={addTestimonial}
          id="add-form"
          itemSpace="1rem"
          align="center"
          margin="1rem 0 0 0"
          responsive="576px"
          rItemSpace="1rem"
          rAlign="flex-start"
        >
          <VStack itemSpace="1rem" w="100%">
            <InputField>
              <p>Patient Name</p>
              <InputText
                required
                placeholder="Patient Name"
                name="patientName"
              />
            </InputField>
            <InputField>
              <p>Testimonial</p>
              <Textarea
                required
                placeholder="Enter the testimonial here...!"
                name="testimonial"
              />
            </InputField>
          </VStack>
          <PrimaryButton type="submit" className="ml-auto">
            Add
          </PrimaryButton>
        </HStack>
      </VStack>
      <Line />
      <VStack>
        <h2>Testimonials</h2>
        {testimonails.length > 0 && (
          <OrderedList>
            {testimonails.map((item, i) => {
              return (
                <li key={i}>
                  {editActive && editIDRef.current === item.Testimonial_ID ? (
                    <HStack
                      as="form"
                      id="update-form"
                      itemSpace="1rem"
                      align="center"
                      margin="1rem 0 0 0"
                      padding="1rem"
                      className="bg-grey"
                      onSubmit={updateTestimonial}
                      responsive="576px"
                      rItemSpace="1rem"
                      rAlign="flex-start"
                    >
                      <VStack itemSpace="1rem" responsive="576px" rw="100%">
                        <InputField>
                          <p>Patient Name</p>
                          <InputText
                            required
                            name="patientName"
                            defaultValue={item.Patient_Name}
                          />
                        </InputField>
                        <InputField>
                          <p>Testimonial</p>
                          <Textarea
                            required
                            name="testimonial"
                            defaultValue={item.Testimonial}
                          />
                        </InputField>
                      </VStack>
                      <VStack
                        itemSpace="1rem"
                        w="fit-content"
                        responsive="576px"
                        rw="100%"
                        direction="row"
                        rItemSpace="1rem"
                        rJustify="flex-end"
                      >
                        <PrimaryButton type="submit">Update</PrimaryButton>
                        <OutlineButton
                          onClick={() => {
                            setEditActive(false);
                          }}
                        >
                          Cancel
                        </OutlineButton>
                      </VStack>
                    </HStack>
                  ) : (
                    <HStack
                      itemSpace="1rem"
                      align="center"
                      margin="1rem 0 0 0"
                      padding="1rem"
                      responsive="576px"
                      rItemSpace="1rem"
                      rAlign="flex-start"
                    >
                      <VStack>
                        <h3>{item.Patient_Name}</h3>
                        <p>{item.Testimonial}</p>
                      </VStack>
                      <PrimaryButton
                        className="ml-auto"
                        onClick={() => {
                          setEditActive(true);
                          editIDRef.current = item.Testimonial_ID;
                        }}
                      >
                        Edit
                      </PrimaryButton>
                    </HStack>
                  )}
                </li>
              );
            })}
          </OrderedList>
        )}
      </VStack>
    </VStack>
  );
}

export default Testimonial;
