import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FlexContainer } from "../../styledComponents/GlobalElements";
import Step1 from "./registerComponents/Step1";
import Step2 from "./registerComponents/Step2";
import Step3 from "./registerComponents/Step3";

function Register() {
  const location = useLocation();
  const userExistsRef = useRef(location.state && location.state.userExists);
  const [count, setCount] = useState(userExistsRef.current ? 2 : 1);
  const usernameRef = useRef({
    email: location.state && location.state.email,
    phone: location.state && location.state.phone,
    doctorID: location.state && location.state.doctorID,
  });
  const formFieldsRef = useRef({
    email: location.state && location.state.email,
    phone: location.state && location.state.phone,
    doctorID: location.state && location.state.doctorID,
  });

  useEffect(() => {
    if (userExistsRef.current) {
      // setCount((prevCount) => prevCount + 1);
      setCount(2);
    }
  }, [setCount, location.state]);

  return (
    <FlexContainer>
      {count === 1 ? (
        <Step1
          setCount={setCount}
          formFieldsRef={formFieldsRef}
          usernameRef={usernameRef}
        />
      ) : null}
      {count === 2 ? (
        <Step2
          setCount={setCount}
          formFieldsRef={formFieldsRef}
          userExists={userExistsRef.current}
        />
      ) : null}
      {count === 3 ? (
        <Step3 setCount={setCount} formFieldsRef={formFieldsRef} />
      ) : null}
    </FlexContainer>
  );
}

export default Register;
