import React, { useCallback, useContext } from "react";
import Axios from "axios";
import {
  VStack,
  HStack,
  InputField,
  InputText,
  PrimaryButton,
  CustomRadio,
  ErrorMessage,
  Textarea,
} from "../../../styledComponents/GlobalElements";
import { useFormValidation } from "../../../globalComponents/validation/useFormValidation";
import { doctorAccInfoValidations } from "../../../globalComponents/validation/validationConstraints";
import { GlobalContext } from "../../../globalComponents/context/GlobalContextApi";

var formLoopData = {};

function InfoStep({ formFields, fetchUserDetails }) {
  const { handleValidation, errors } = useFormValidation({
    data: formLoopData,
    validations: {
      ...doctorAccInfoValidations,
    },
  });

  const { setSnackbarMessage } = useContext(GlobalContext);

  const handleDataForValidation = useCallback(async () => {
    var data = new FormData(document.getElementById("updateInfo-form"));

    for (var [key, value] of data) {
      formLoopData[key] = value;
    }
    handleValidation(() => {
      // Callback fn runs on no errors
      Axios({
        method: "POST",
        data: formLoopData,
        withCredentials: true,
        url: "/doctor/updateUserDetails",
      }).then((res) => {
        if (res) {
          fetchUserDetails();
          setSnackbarMessage(res.data.msg);
        }
      });
    });
  }, [handleValidation, setSnackbarMessage, fetchUserDetails]);

  return (
    <VStack
      as="form"
      id="updateInfo-form"
      padding="1rem"
      align="center"
      justify="center"
      itemSpace="1rem"
    >
      <HStack itemSpace="2rem" w="100%" responsive="576px" rItemSpace="1rem">
        <InputField>
          First Name
          <InputText
            className={errors.firstName ? "error" : ""}
            required
            placeholder="First Name"
            type="text"
            name="firstName"
            defaultValue={formFields.First_Name}
          />
          {errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
        </InputField>
        <InputField>
          Last Name
          <InputText
            className={errors.lastName ? "error" : ""}
            required
            placeholder="Last Name"
            type="text"
            name="lastName"
            defaultValue={formFields.Last_Name}
          />
          {errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
        </InputField>
      </HStack>
      <HStack itemSpace="2rem" w="100%" responsive="576px" rItemSpace="1rem">
        <InputField>
          Email
          <InputText
            required
            placeholder="Email"
            type="text"
            name="email"
            defaultValue={formFields.Email}
            disabled
          />
          {/* {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>} */}
        </InputField>
        <InputField>
          Phone
          <InputText
            required
            placeholder="Phone"
            type="text"
            name="phone"
            defaultValue={formFields.Phone}
            disabled
          />
          {/* {errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>} */}
        </InputField>
      </HStack>
      <VStack>
        <p>Gender</p>
        <HStack w="100%">
          <CustomRadio htmlFor="male">
            Male
            <input
              required
              id="male"
              name="gender"
              value="male"
              type="radio"
              defaultChecked={formFields.Gender === "male" ? true : null}
            />
            <span
              className={errors.gender ? "error checkmark" : "checkmark"}
            ></span>
          </CustomRadio>
          <CustomRadio htmlFor="female">
            Female
            <input
              id="female"
              name="gender"
              value="female"
              type="radio"
              defaultChecked={formFields.Gender === "female" ? true : null}
            />
            <span
              className={errors.gender ? "error checkmark" : "checkmark"}
            ></span>
          </CustomRadio>
          <CustomRadio htmlFor="others">
            Others
            <input
              id="others"
              name="gender"
              value="others"
              type="radio"
              defaultChecked={formFields.Gender === "others" ? true : null}
            />
            <span
              className={errors.gender ? "error checkmark" : "checkmark"}
            ></span>
          </CustomRadio>
        </HStack>
        {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
      </VStack>
      <InputField>
        Intro Summary
        <Textarea
          required
          type="text"
          name="introSummary"
          rows="9"
          defaultValue={formFields.Intro_Summary}
          placeholder="Tell patients about yourself"
        ></Textarea>
        {errors.introSummary && (
          <ErrorMessage>{errors.introSummary}</ErrorMessage>
        )}
      </InputField>
      <PrimaryButton
        className="ml-auto"
        type="button"
        onClick={() => {
          handleDataForValidation();
        }}
      >
        Update Information
      </PrimaryButton>
    </VStack>
  );
}

export default InfoStep;
