import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  background-color: black;
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    position: sticky;
    top: 0px;
    z-index: 9999;
  }
`;
export const NavbarBrand = styled.div`
  font-family: "Playfair Display", sans-serif;
  font-weight: 400;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  a {
    color: white;
  }
`;
export const DropdownToggle = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    margin: 0px 8px;
    color: white;
  }
  svg {
    color: white;
  }
`;
