import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Dropdown from "./dropdown/Dropdown";
import { DropdownToggle } from "./NavbarElements";
import { ChevronUp, ChevronDown } from "react-feather";
import ClickOutsideClose from "../../../globalComponents/useClickOutside";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;

  & > li {
    position: relative;
    padding: 18px 0px;

    & > a {
      color: white;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    flex-flow: column nowrap;
    background-color: black;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding: 3.5rem 1rem 3.5rem;
    transition: transform 0.3s ease-in-out;
    & > li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open, user }) => {
  const [dropdownActive, setDropdownActive] = useState(false);

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };
  return (
    <Ul open={open}>
      <li>
        <NavLink aria-current="page" to="/doctor/dashboard">
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink to="/doctor/introVideo">Intro Video</NavLink>
      </li>
      <li>
        <NavLink to="/doctor/caseImages">Case Images</NavLink>
      </li>
      <li>
        <NavLink to="/doctor/help">Help</NavLink>
      </li>
      <li>
        <ClickOutsideClose
          cb={() => {
            setDropdownActive(false);
          }}
        >
          <DropdownToggle onClick={toggleDropdown}>
            <span>{user && user.FullName}</span>
            {dropdownActive ? (
              <ChevronUp size={20} />
            ) : (
              <ChevronDown size={20} />
            )}
          </DropdownToggle>
          <Dropdown dropdownActive={dropdownActive} />
        </ClickOutsideClose>
      </li>
    </Ul>
  );
};

export default RightNav;
