import React, { useCallback } from "react";
import { Camera, PlayCircle, Calendar } from "react-feather";
import {
  HStack,
  VStack,
  InputText,
  ErrorMessage,
  InputField,
  CustomRadio,
  PrimaryButton,
} from "../../../styledComponents/GlobalElements";
import { ArrowRight } from "react-feather";
import { useFormValidation } from "../../../globalComponents/validation/useFormValidation";
import { patientRegValidationStep1 } from "../../../globalComponents/validation/validationConstraints";

var formLoopData = {};

function Step1({ setCount, formFieldsRef, usernameRef }) {
  const { handleValidation, errors } = useFormValidation({
    data: formLoopData,
    validations: {
      ...patientRegValidationStep1,
      // special case for confirm pass as it requires other values
      confirmPassword: {
        required: {
          value: true,
          message: "Password is required",
        },
        custom: {
          isValid: (value) => value === formLoopData.password,
          message: "Passwords do not match",
        },
      },
    },
  });

  const handleDataForValidation = useCallback(async () => {
    var data = new FormData(document.getElementById("register-form"));

    for (var [key, value] of data) {
      formLoopData[key] = value;
    }
    formFieldsRef.current = {
      ...formFieldsRef.current,
      ...formLoopData,
    };
    handleValidation(() => {
      // Callback fn runs on no errors
      setCount((prevCount) => prevCount + 1);
    });
  }, [formFieldsRef, handleValidation, setCount]);

  return (
    <>
      <HStack w="100%" h="100%" responsive="576px">
        {/* left section */}
        <VStack
          w="50%"
          className="bg-lightGrey"
          padding="5rem"
          align="center"
          justify="center"
          responsive="576px"
          rw="100%"
        >
          <p>Step 1 of 3</p>
          <React.Fragment>
            <h1 className="text-center">
              Let's get started to mend your beautiful smile.
            </h1>
            <ul>
              <li>
                <Camera size={36} />
                <p>
                  Upload a close-up smile selfie. This is also an opportunity to
                  share any concerns or desires you have regarding your smile.
                </p>
              </li>
              <li>
                <PlayCircle size={36} />
                <p>
                  Your dentist will send you a customized video addressing your
                  questions and concerns. This video will also contain your
                  unique, personalized recommendations.
                </p>
              </li>
              <li>
                <Calendar size={36} />
                <p>
                  Once you've reviewed your recommendations, you can schedule an
                  in-person visit with the dentist.
                </p>
              </li>
            </ul>
          </React.Fragment>
        </VStack>
        {/* right section */}
        <VStack
          w="50%"
          as="form"
          id="register-form"
          padding="1rem"
          align="center"
          justify="center"
          itemSpace="1rem"
          responsive="576px"
          rw="100%"
        >
          <HStack
            itemSpace="2rem"
            w="100%"
            responsive="576px"
            rItemSpace="1rem"
          >
            <InputField>
              <InputText
                className={errors.firstName ? "error" : ""}
                required
                placeholder="First Name"
                type="text"
                name="firstName"
                defaultValue={formFieldsRef.current.firstName}
              />
              {errors.firstName && (
                <ErrorMessage>{errors.firstName}</ErrorMessage>
              )}
            </InputField>
            <InputField>
              <InputText
                className={errors.lastName ? "error" : ""}
                required
                placeholder="Last Name"
                type="text"
                name="lastName"
                defaultValue={formFieldsRef.current.lastName}
              />
              {errors.lastName && (
                <ErrorMessage>{errors.lastName}</ErrorMessage>
              )}
            </InputField>
          </HStack>
          <VStack>
            <p>Gender</p>
            <HStack w="100%">
              <CustomRadio htmlFor="male">
                Male
                <input
                  required
                  id="male"
                  name="gender"
                  value="male"
                  type="radio"
                  defaultChecked={
                    formFieldsRef.current.gender === "male" ? true : null
                  }
                />
                <span
                  className={errors.gender ? "error checkmark" : "checkmark"}
                ></span>
              </CustomRadio>
              <CustomRadio htmlFor="female">
                Female
                <input
                  id="female"
                  name="gender"
                  value="female"
                  type="radio"
                  defaultChecked={
                    formFieldsRef.current.gender === "female" ? true : null
                  }
                />
                <span
                  className={errors.gender ? "error checkmark" : "checkmark"}
                ></span>
              </CustomRadio>
              <CustomRadio htmlFor="others">
                Others
                <input
                  id="others"
                  name="gender"
                  value="others"
                  type="radio"
                  defaultChecked={
                    formFieldsRef.current.gender === "others" ? true : null
                  }
                />
                <span
                  className={errors.gender ? "error checkmark" : "checkmark"}
                ></span>
              </CustomRadio>
            </HStack>
            {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
          </VStack>
          <InputField>
            <InputText
              className={errors.email ? "error" : ""}
              required
              placeholder="Email"
              type="email"
              name="email"
              defaultValue={
                usernameRef.current.email
                  ? usernameRef.current.email
                  : formFieldsRef.current.email
              }
              readOnly={usernameRef.current.email ? "{true}" : ""}
            />
            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </InputField>
          <InputField>
            <InputText
              className={errors.phone ? "error" : ""}
              required
              placeholder="Phone"
              type="phone"
              name="phone"
              defaultValue={
                usernameRef.current.phone
                  ? usernameRef.current.phone
                  : formFieldsRef.current.phone
              }
              readOnly={usernameRef.current.phone ? "{true}" : ""}
            />
            {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
          </InputField>
          <InputField>
            <InputText
              className={errors.postalCode ? "error" : ""}
              required
              placeholder="Postal Code"
              type="text"
              name="postalCode"
              defaultValue={formFieldsRef.current.postalCode}
            />
            {errors.postalCode && (
              <ErrorMessage>{errors.postalCode}</ErrorMessage>
            )}
          </InputField>
          <HStack
            itemSpace="2rem"
            w="100%"
            responsive="576px"
            rItemSpace="1rem"
          >
            <InputField>
              <InputText
                className={errors.password ? "error" : ""}
                required
                placeholder="Password"
                type="password"
                name="password"
              />
              {errors.password && (
                <ErrorMessage>{errors.password}</ErrorMessage>
              )}
            </InputField>
            <InputField>
              <InputText
                className={errors.confirmPassword ? "error" : ""}
                required
                placeholder="Confirm Password"
                type="password"
                name="confirmPassword"
              />
              {errors.confirmPassword && (
                <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
              )}
            </InputField>
          </HStack>
          <PrimaryButton
            className="btn btn-primary ml-auto"
            type="button"
            onClick={() => {
              handleDataForValidation();
            }}
          >
            Next <ArrowRight size={24} />
          </PrimaryButton>
        </VStack>
      </HStack>
    </>
  );
}

export default Step1;
