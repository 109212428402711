import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  white-space: nowrap;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 1em;
  tr {
    th,
    td {
      padding: 10px 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    td:last-child {
      text-align: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      button {
        margin: auto;
      }
    }
  }
  tbody {
    tr {
      background-color: #f5f5f5;
    }
  }
  @media screen and (max-width: 850px) {
    margin-top: 1rem;

    caption {
    }

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      box-shadow: 0 2px 3px 1px rgb(0 0 0 / 10%);
      display: block;
      margin-bottom: 0.625em;
      border-radius: 5px;
    }

    td {
      border-bottom: 1px solid #ddd;
      display: block;
      text-align: right;
    }

    td::before {
      /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      margin-right: 1rem;
    }

    td:last-child {
      border-bottom: 0;
    }
  }
`;
