import React, { useState } from "react";
import {
  MSDContainer,
  MSDSelected,
  MSDValues,
} from "./MultiSelectDropdownElements";
import { ChevronDown, X } from "react-feather";

function MultiSelectDropdown({ services, selectedValues, setSelectedValues }) {
  const [isActive, setIsActive] = useState(false);

  const addHandler = (item) => {
    setSelectedValues([...selectedValues, item]);
  };
  const removeHandler = (id) => {
    setSelectedValues(selectedValues.filter((i) => i !== id));
  };
  const toggleDropdown = () => {
    setIsActive(!isActive);
  };

  return (
    <MSDContainer>
      <MSDSelected>
        <MSDValues>
          {selectedValues.length ? (
            selectedValues.map((item, i) => {
              return (
                <div key={i} className="item">
                  <span>{item.label}</span>
                  <button type="button" onClick={() => removeHandler(item)}>
                    <X size={14} />
                  </button>
                </div>
              );
            })
          ) : (
            <div onClick={toggleDropdown} className="dropdown-placeholder">
              Services Provided
            </div>
          )}
        </MSDValues>
        <span onClick={toggleDropdown}>
          <ChevronDown size={24} />
        </span>
      </MSDSelected>
      {isActive && (
        <ul className="dropdown-options">
          {services
            .filter(
              (ser) => selectedValues.findIndex((v) => v.id === ser.id) === -1
            )
            .map((item, i) => {
              return (
                <li
                  key={i}
                  className="dropdown-option"
                  value={item.id}
                  data-test={i}
                  onClick={() => addHandler(item)}
                >
                  {item.label}
                </li>
              );
            })}
        </ul>
      )}
    </MSDContainer>
  );
}

export default MultiSelectDropdown;
