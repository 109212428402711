import React from "react";
import toothIcon from "../../assets/icons/views_icons/tooth.svg";
import calendarIcon from "../../assets/icons/views_icons/calendar.svg";
import walletIcon from "../../assets/icons/views_icons/wallet.svg";
import {
  HStack,
  Icon,
  IconColumn,
  InputText,
} from "../../styledComponents/GlobalElements";

function YourRecommendation({ recommendations, edit, updateValues }) {
  // console.log("your rec updateValues :", updateValues);
  return (
    <HStack itemSpace="1.5rem" responsive="576px" rItemSpace="2rem">
      <IconColumn itemSpace="1.5rem" responsive="576px" rItemSpace="0rem">
        <Icon src={toothIcon} alt="toothIcon" />
        <h5>Treatment</h5>
        {!edit ? (
          <h2>
            <p>{recommendations.suggestedTreatment}</p>
          </h2>
        ) : (
          <InputText
            type="text"
            placeholder="Eg: Veneer"
            defaultValue={recommendations.suggestedTreatment}
            onChange={(e) => {
              updateValues.suggestedTreatment = e.target.value;
            }}
          />
        )}
      </IconColumn>
      <IconColumn itemSpace="1.5rem" responsive="576px" rItemSpace="0rem">
        <Icon src={calendarIcon} alt="toothIcon" />
        <h5>Appointments</h5>
        {!edit ? (
          <h2>
            {recommendations.requiredAppointments[0]} -{" "}
            {recommendations.requiredAppointments[1]}
          </h2>
        ) : (
          <HStack
            itemSpace="1.5rem"
            responsive="576px"
            rItemSpace="1.5rem"
            rw="100%"
          >
            <InputText
              type="number"
              placeholder="Eg: 1"
              defaultValue={recommendations.requiredAppointments[0]}
              onChange={(e) => {
                updateValues.requiredAppointments[0] =
                  parseInt(e.target.value) || "";
              }}
            />
            <InputText
              type="number"
              placeholder="Eg: 5"
              defaultValue={recommendations.requiredAppointments[1]}
              onChange={(e) => {
                updateValues.requiredAppointments[1] =
                  parseInt(e.target.value) || "";
              }}
            />
          </HStack>
        )}
      </IconColumn>
      <IconColumn itemSpace="1.5rem" responsive="576px" rItemSpace="0rem">
        <Icon src={walletIcon} alt="toothIcon" />
        <h5>Investment</h5>
        {!edit ? (
          <h2>
            $
            {recommendations.investment[0] &&
              recommendations.investment[0]
                .toFixed(0)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
            to $
            {recommendations.investment[1] &&
              recommendations.investment[1]
                .toFixed(0)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </h2>
        ) : (
          <HStack
            itemSpace="1.5rem"
            responsive="576px"
            rItemSpace="1.5rem"
            rw="100%"
          >
            <InputText
              type="number"
              placeholder="Eg: 100"
              defaultValue={recommendations.investment[0]}
              onChange={(e) => {
                updateValues.investment[0] = parseInt(e.target.value) || "";
              }}
            />
            <InputText
              type="number"
              placeholder="Eg: 500"
              defaultValue={recommendations.investment[1]}
              onChange={(e) => {
                updateValues.investment[1] = parseInt(e.target.value) || "";
              }}
            />
          </HStack>
        )}
      </IconColumn>
    </HStack>
  );
}

export default YourRecommendation;
