import Axios from "axios";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Header,
  OutlineButton,
  VStack,
  HStack,
  Card,
} from "../../styledComponents/GlobalElements";
import Gallery from "../../globalComponents/Gallery";
import { GlobalContext } from "../../globalComponents/context/GlobalContextApi";

const RowCols = styled(HStack)`
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  & > div {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: 576px) {
      width: 50%;
    }
    @media (min-width: 768px) {
      width: 33.3333%;
    }
    @media (min-width: 992px) {
      width: 25%;
    }
  }
`;

function CaseImages() {
  const navigate = useNavigate();
  const [services, setServices] = useState([]); // Service object from db.
  const [images, setImages] = useState([]);
  const [serviceName, setServiceName] = useState([]);

  const { imgBaseUrl } = useContext(GlobalContext);

  const mediaIDsRef = useRef([]);
  const fetchImages = (serviceID) => {
    Axios({
      method: "POST",
      data: { serviceID },
      withCredentials: true,
      url: "/doctor/getCaseImages",
    }).then((res) => {
      //   setLoading(false);
      if (res.data.status) {
        setImages(res.data.result);
      } else {
        setImages([]);
      }
    });
  };
  useEffect(() => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/doctor/doctorServices",
    }).then((res) => {
      if (res.data.status) {
        setServices(res.data.result);
      }
    });
  }, []);
  return (
    <VStack
      as="section"
      margin="1.5rem 0px"
      padding="1.5rem"
      itemSpace="1.5rem"
      className="m-w-1140"
    >
      <Header>
        <h1>Case Images</h1>
        <OutlineButton onClick={() => navigate("/doctor/dashboard")}>
          Dashboard
        </OutlineButton>
      </Header>
      <RowCols>
        {services.map((service, i) => {
          return (
            <div key={i}>
              <Card
                key={i}
                onClick={() => {
                  // setLoading(true);
                  setServiceName({
                    serviceName: service.Name,
                    serviceID: service.Service_ID,
                  });
                  fetchImages(service.Service_ID);
                }}
              >
                <div className="card-header">{service.Name}</div>
                <img
                  src={`${imgBaseUrl}/service_images/i_${service.Service_ID}_1.jpeg`}
                  alt={service.Name}
                />
              </Card>
            </div>
          );
        })}
      </RowCols>
      {images && serviceName.serviceName ? (
        <Gallery
          images={images}
          mediaIDs={mediaIDsRef.current}
          serviceName={serviceName.serviceName}
          services={serviceName.serviceID}
          fetchImages={fetchImages}
        />
      ) : null}
    </VStack>
  );
}

export default CaseImages;
