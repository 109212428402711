import styled from "styled-components";

export const MSDContainer = styled.div`
  .dropdown-options {
    top: 100%;
    background-color: #ffffff;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 4px 11px;
    margin-bottom: 8px;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    z-index: 10;
    border-radius: 5px;
    max-height: 150px;
    overflow-y: auto;
    list-style: none;
    padding: 4px 0;

    .dropdown-option {
      background-color: transparent;
      color: inherit;
      cursor: default;
      display: block;
      font-size: inherit;
      padding: 8px 12px;
      width: 100%;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      height: fit-content;

      &:hover {
        background-color: rgb(222, 235, 255);
      }
    }

    .dropdown-option + .dropdown-option {
      border-top: 1px solid #f2f2f2;
    }
  }
`;
export const MSDSelected = styled.div`
  border: 2px solid black;
  border-radius: 5px;
  display: flex;
  align-items: center;

  & > span {
    padding: 9px;
    cursor: pointer;
    border-left: 2px solid black;
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
  }
  &.error {
    border-color: red;
  }
`;
export const MSDValues = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  padding: 13px 15px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  .item {
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    display: flex;
    min-width: 0px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 2px;

    span {
      border-radius: 2px;
      color: rgb(51, 51, 51);
      font-size: 14px;
      overflow: hidden;
      padding: 2px 2px 2px 6px;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }

    button {
      -webkit-box-align: center;
      align-items: center;
      border: none;
      border-radius: 2px;
      background-color: transparent;
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      box-sizing: border-box;

      &:hover {
        background-color: rgb(255, 189, 173);
        color: rgb(222, 53, 11);
      }
    }

    .dropdown-placeholder {
      width: 100%;
      margin-left: 9px;
      color: grey;
      cursor: pointer;
    }
  }
`;
