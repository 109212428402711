import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  VStack,
  PrimaryButton,
  ErrorMessage,
} from "../../styledComponents/GlobalElements.js";

function Index() {
  const navigate = useNavigate();
  const [doctor_ID, setDoctor_ID] = useState();
  const [doctorList, setDoctorList] = useState([]);

  const [errorMsg, setErrorMsg] = useState("");

  const redirectHandler = () => {
    if (doctor_ID) {
      navigate(`/patient/login?doctorID=${doctor_ID}`);
    } else {
      setErrorMsg("Please select a doctor");
    }
  };

  useEffect(() => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/patient/doctorsList",
    }).then((res) => {
      if (res.data.status) {
        setDoctorList(res.data.result);
      }
    });
  }, []);
  return (
    <VStack w="100%" h="100%" align="center" justify="center">
      <VStack className="index-box">
        <p>Welcome to MendSmiles</p>
        <h1 className="text-center">Find Your Doctor</h1>
        <VStack margin="1rem 0 0 0">
          {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
          <select
            required
            className="form-select"
            id="selectDoctor"
            name="selectDoctor"
            onChange={(e) => {
              setDoctor_ID(e.target.value);
              setErrorMsg("");
            }}
          >
            <option defaultValue value="">
              Select Doctor
            </option>
            {doctorList.length > 0 &&
              doctorList.map((doc, i) => {
                return (
                  <option key={doc.Doctor_ID} value={doc.Doctor_ID}>
                    Dr. {doc.First_Name} {doc.Last_Name}
                  </option>
                );
              })}
          </select>
        </VStack>
        <PrimaryButton onClick={redirectHandler}>
          Book Appointment
        </PrimaryButton>
      </VStack>
    </VStack>
  );
}

export default Index;
