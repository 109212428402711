import React, { useContext } from "react";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/Footer";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import ViewAppointment from "./pages/ViewAppointment";
import RecordingPage from "./pages/RecordingPage";
import CaseImages from "./pages/CaseImages";
import Account from "./pages/Account";
import IntroVideo from "./pages/IntroVideo";
import Testimonial from "./pages/Testimonial";
import { GlobalContext } from "../globalComponents/context/GlobalContextApi";
import Logo from "./pages/Logo";

function DoctorIndex() {
  const location = useLocation();
  const { user } = useContext(GlobalContext);

  return (
    <>
      <Navbar user={user} />
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/viewAppointment" element={<ViewAppointment />} />
        <Route path="/recordingPage" element={<RecordingPage />} />
        <Route path="/caseImages" element={<CaseImages />} />
        <Route path="/introVideo" element={<IntroVideo />} />
        <Route path="/account" element={<Account />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/logo" element={<Logo />} />
        <Route
          path="/*"
          element={<Navigate to="/error" replace state={{ from: location }} />}
        />
      </Routes>
      <Footer />
    </>
  );
}

export default DoctorIndex;
