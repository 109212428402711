import React, { useState, useRef, useContext } from "react";
import Axios from "axios";
import { Camera } from "react-feather";
import {
  Heading,
  ProfilePicture,
  VStack,
  Line,
  CustomFileUpload,
} from "../../../styledComponents/GlobalElements";
import ImageCrop from "../../../globalComponents/ImageCrop";
import Modal from "../../../globalComponents/Modal";
import { GlobalContext } from "../../../globalComponents/context/GlobalContextApi";

function ProfilePictureStep({ formFields, fetchUserDetails }) {
  const croppedImageRef = useRef();

  const { imgBaseUrl, setSnackbarMessage } = useContext(GlobalContext);

  const modalChildrenRef = useRef();
  const modalSaveHandlerRef = useRef();
  const modalHeaderRef = useRef();

  const [modalActive, setModalActive] = useState(false);
  const modalToggleHandler = () => {
    setModalActive(!modalActive);
  };

  const updateProfilePicture = () => {
    const formData = new FormData();
    formData.append("profile_picture", croppedImageRef.current);
    setModalActive(false);
    Axios({
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
      url: "/doctor/uploadProfilePicture",
    }).then((res) => {
      if (res.data.status) {
        setModalActive(false);
        setSnackbarMessage("Profile Picture updated");
        fetchUserDetails();
      }
    });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        modalChildrenRef.current = (
          <ImageCrop
            propImg={reader.result}
            croppedImage={croppedImageRef}
            aspect={4 / 3}
          />
        );
        modalHeaderRef.current = "Upload Profile Picture";
        modalSaveHandlerRef.current = updateProfilePicture;
        modalToggleHandler();
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <VStack
        as="form"
        id="updateInfo-form"
        //   padding="1rem"
        align="center"
        justify="center"
        itemSpace="1rem"
      >
        {formFields.Picture ? (
          <ProfilePicture
            src={`${imgBaseUrl}/profile_pictures/${formFields.Picture}`}
            alt={`Dr. ${formFields.First_Name}`}
          />
        ) : (
          <Heading>No Image Uploaded</Heading>
        )}
        <Line />
        <CustomFileUpload>
          <label htmlFor="actual-btn">
            <span>Upload Profile Picture</span>
            <Camera />
            <input
              type="file"
              accept="image/*"
              name="profile_picture"
              id="actual-btn"
              onChange={onSelectFile}
              hidden
            />
          </label>
        </CustomFileUpload>
      </VStack>
      {modalActive && (
        <Modal
          close={modalToggleHandler}
          header={modalHeaderRef.current}
          saveHandler={modalSaveHandlerRef.current}
        >
          {modalChildrenRef.current}
        </Modal>
      )}
    </>
  );
}

export default ProfilePictureStep;
