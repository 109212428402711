import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Header,
  OutlineButton,
  VStack,
  NavTabs,
  TabContent,
} from "../../styledComponents/GlobalElements";
import PasswordStep from "./accountComponents/PasswordStep";
import InfoStep from "./accountComponents/InfoStep";
import Snackbar from "../../globalComponents/Snackbar";

function Account() {
  const navigate = useNavigate();
  const [formFields, setformFields] = useState({});
  const [tab, setTab] = useState("accountInfo");

  useEffect(() => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/patient/userDetails",
    }).then((res) => {
      if (res.data.status) {
        setformFields(res.data.result);
      }
    });
  }, []);

  return (
    <VStack
      as="section"
      margin="1.5rem 0px"
      padding="1.5rem"
      itemSpace="1.5rem"
      className="m-w-1140"
    >
      <Header>
        <h1>Account Settings</h1>
        <OutlineButton onClick={() => navigate("/patient/dashboard")}>
          Dashboard
        </OutlineButton>
      </Header>
      <VStack>
        <NavTabs>
          <OutlineButton
            className={tab === "pass" ? "" : "active"}
            onClick={() => {
              setTab("accountInfo");
            }}
          >
            Account Information
          </OutlineButton>
          <OutlineButton
            className={tab === "accountInfo" ? "" : "active"}
            onClick={() => {
              setTab("pass");
            }}
          >
            Change Password
          </OutlineButton>
        </NavTabs>
        <TabContent>
          {tab === "pass" ? (
            <PasswordStep />
          ) : (
            <InfoStep formFields={formFields} />
          )}
        </TabContent>
      </VStack>
      <Snackbar />
    </VStack>
  );
}

export default Account;
