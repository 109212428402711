import React from "react";
import { useLocation } from "react-router-dom";
import {
  FlexContainer,
  PrimaryButton,
  NotFound,
} from "../styledComponents/GlobalElements";

export default function ErrorPage() {
  let location = useLocation();
  return (
    <FlexContainer>
      <NotFound>
        <p>Yikes, looks like your are lost.</p>
        <h1>
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </h1>
        <p>
          The page{" "}
          <strong>
            {(location.state && location.state.from.pathname) ||
              location.pathname}
          </strong>{" "}
          does not exit.
        </p>
        <PrimaryButton>Go to homepage</PrimaryButton>
      </NotFound>
    </FlexContainer>
  );
}
