import React, { useState, useRef } from "react";
import { FlexContainer } from "../../styledComponents/GlobalElements";
import Step2 from "./registerComponents/Step2";
import Step1 from "./registerComponents/Step1";

function Register() {
  const [count, setCount] = useState(1);

  const formFieldsRef = useRef({});
  return (
    <FlexContainer>
      {count === 1 ? (
        <Step1 setCount={setCount} formFieldsRef={formFieldsRef} />
      ) : null}
      {count === 2 ? (
        <Step2 setCount={setCount} formFieldsRef={formFieldsRef} />
      ) : null}
    </FlexContainer>
  );
}

export default Register;
