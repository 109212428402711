import React, { useCallback, useContext } from "react";
import Axios from "axios";
import { useFormValidation } from "../../../globalComponents/validation/useFormValidation";
import { accountPasswordValidation } from "../../../globalComponents/validation/validationConstraints";
import {
  VStack,
  InputText,
  ErrorMessage,
  InputField,
  PrimaryButton,
} from "../../../styledComponents/GlobalElements";
import { GlobalContext } from "../../../globalComponents/context/GlobalContextApi";

var formLoopData = {};

function PasswordStep({ fetchUserDetails }) {
  const { handleValidation, errors } = useFormValidation({
    data: formLoopData,
    validations: {
      ...accountPasswordValidation,
      // special case for confirm pass as it requires other values
      confirmPassword: {
        required: {
          value: true,
          message: "Password is required",
        },
        custom: {
          isValid: (value) => value === formLoopData.password,
          message: "Passwords do not match",
        },
      },
    },
  });

  const { setSnackbarMessage } = useContext(GlobalContext);

  const handleDataForValidation = useCallback(async () => {
    var data = new FormData(document.getElementById("updateInfo-form"));

    for (var [key, value] of data) {
      formLoopData[key] = value;
    }
    handleValidation(() => {
      // Callback fn runs on no errors
      Axios({
        method: "POST",
        data: formLoopData,
        withCredentials: true,
        url: "/doctor/updateUserPassword",
      }).then((res) => {
        if (res) {
          setSnackbarMessage(res.data.msg);
          fetchUserDetails();
        }
      });
    });
  }, [handleValidation, setSnackbarMessage, fetchUserDetails]);

  return (
    <VStack itemSpace="1rem" as="form" id="updateInfo-form">
      <InputField>
        <InputText
          required
          placeholder="Current Password"
          type="password"
          name="currentPassword"
        />
      </InputField>
      <InputField>
        <InputText
          className={errors.password ? "error" : ""}
          required
          placeholder="New Password"
          type="password"
          name="password"
        />
        {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
      </InputField>
      <InputField>
        <InputText
          className={errors.confirmPassword ? "error" : ""}
          required
          placeholder="Confirm Password"
          type="password"
          name="confirmPassword"
        />
        {errors.confirmPassword && (
          <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
        )}
      </InputField>
      <PrimaryButton
        type="button"
        className="ml-auto"
        onClick={handleDataForValidation}
      >
        Change Password
      </PrimaryButton>
    </VStack>
  );
}

export default PasswordStep;
