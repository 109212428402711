import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import {
  OutlineButton,
  PrimaryButton,
  VStack,
  HStack,
  Heading,
} from "../../styledComponents/GlobalElements";
// Import Swiper React components
import { SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core";
import { Swiper } from "swiper/react";
import { useParams } from "react-router-dom";
import ErrorPage from "../../globalComponents/ErrorPage";
import styled from "styled-components";
import HeroImage from "../../assets/images/landing-hero-image.png";
import BackgroundPattern from "../../assets/images/pattern.png";
import { ReactComponent as Consultation } from "../../assets/icons/views_icons/consultation.svg";
import { Camera, PlayCircle, ArrowRight } from "react-feather";
import { GlobalContext } from "../../globalComponents/context/GlobalContextApi";
import Footer from "../components/Footer";
import Loader from "../../globalComponents/Loader";

export const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-right: auto;
    margin-bottom: 0;
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
      margin-bottom: 1rem;
    }
  }
`;
export const NavPills = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  .nav-item + .nav-item {
    margin-left: 1rem;
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    width: 100%;
    .nav-item,
    .nav-link {
      flex-basis: 0;
      flex-grow: 1;
      text-align: center;
    }
    button {
      width: 100%;
    }
  }
`;
export const HeroBanner = styled(HStack)`
  justify-content: center;
  align-items: flex-end;
  background-color: #e0e0e0;
  min-height: 500px;
  padding-top: 1.5rem;
  p {
    margin: 30px 0px !important;
  }
  a {
    /* margin: 30px 0px 0px 0px; */
  }
`;
export const HighlightSection = styled(HStack)`
  padding: 3rem 0;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
`;

export const Card = styled.div`
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

export const HeadingWrapper = styled(VStack)`
  text-align: center;
  flex-direction: column;
  align-self: start;
`;
export const CustomSwiper = styled(Swiper)`
  .swiper-pagination {
    position: relative !important;
    bottom: 0 !important;
    margin-top: 1rem;
  }
  .swiper-pagination-bullet-active {
    background-color: black !important;
  }
  .swiper-pagination-bullet {
    background-color: transparent;
    position: relative;
    border: 2px solid black;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 3px;
    left: 0px;
    z-index: 5;
    opacity: 1 !important;
    transition: background 0.25s linear;

    &:after {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 12px;
      width: 12px;
      top: 0px;
      left: 0px;
      margin: auto;
      border: 2px solid #fff;
      transition: background 0.25s linear;
    }
  }
`;
export const CTA = styled(HighlightSection)`
  position: relative;
  background-color: transparent;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url(${BackgroundPattern});
    background-repeat: repeat-x;
    z-index: -1;
  }
  a {
    margin-top: 2rem;
  }
`;

function LandingPage() {
  const [isLoading, setLoading] = useState(true);
  // install Swiper modules
  SwiperCore.use([Pagination]);
  let { slug } = useParams();
  const { imgBaseUrl } = useContext(GlobalContext);
  const [doctor, setDoctor] = useState({});
  const [testimonails, setTestimonails] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await Axios({
        method: "POST",
        data: { slug: slug.toLowerCase() },
        withCredentials: true,
        url: "/patient/findSlug",
      }).then((response) => {
        if (response.data.status === true) {
          setDoctor(response.data.result[0]);
          setTestimonails(response.data.result);
          setLoading(false);
          //getTestimonial();
        } else {
          setDoctor({});
          setLoading(false);
        }
      });
    }
    fetchData();
  }, [slug]);

  if (isLoading) {
    return <Loader />;
  }

  if (Object.entries(doctor).length === 0) {
    return <ErrorPage />;
  } else {
    return (
      <VStack>
        <VStack padding="0rem 1rem" className="m-w-1140">
          <Header>
            <a className="logo" href="https://www.mendsmile.com">
              <img
                height="100px"
                src={`${imgBaseUrl}/logo_images/${doctor.Logo}`}
                alt={doctor.Business_Name}
              />
            </a>
            <NavPills>
              <li className="nav-item">
                <OutlineButton as="a" href={`${window.origin}/patient/login`}>
                  Login
                </OutlineButton>
              </li>
              <li className="nav-item">
                <PrimaryButton
                  as="a"
                  href={`${window.origin}/patient/login?doctorID=${doctor.Doctor_ID}`}
                >
                  Get Started
                </PrimaryButton>
              </li>
            </NavPills>
          </Header>
        </VStack>
        <HeroBanner>
          <HStack
            w="100%"
            align="center"
            itemSpace="1.5rem"
            responsive="576px"
            className="m-w-1140"
            padding="0rem 1rem"
          >
            <VStack
              h="100%"
              w="66.67%"
              align="flex-start"
              // itemSpace="rem"
              responsive="576px"
              rw="100%"
            >
              <h1 className="w-100">
                Do You Want to Improve Your Smile?
                <br />
                Do You Have a Dental Concern?
              </h1>
              <p>
                Connect with <strong>{doctor.Business_Name}</strong> today and
                get all of your questions answered in three easy steps, without
                ever leaving home!
              </p>
              <PrimaryButton
                as="a"
                href={`${window.origin}/patient/login?doctorID=${doctor.Doctor_ID}`}
              >
                Get Started <ArrowRight />
              </PrimaryButton>
            </VStack>
            <VStack w="33.34%" responsive="576px" rw="100%">
              <img className="w-100" src={HeroImage} alt="Hero Banner" />
            </VStack>
          </HStack>
        </HeroBanner>
        <VStack padding="3rem 1rem" itemSpace="3rem" className="m-w-1140">
          <HeadingWrapper>
            <small>The Process</small>
            <Heading>How It Works</Heading>
          </HeadingWrapper>
          <HStack itemSpace="1.5rem" responsive="576px" rItemSpace="1.5rem">
            <VStack align="center">
              <Camera size={72} strokeWidth="0.6" />
              <p className="text-center">Upload a Selfie</p>
            </VStack>
            <VStack align="center">
              <Consultation />
              <p className="text-center">Tell us your smile goals</p>
            </VStack>
            <VStack align="center">
              <PlayCircle size={72} strokeWidth="0.6" />
              <p className="text-center">
                Check your email in 24-48 hours for your consultation video
              </p>
            </VStack>
          </HStack>
        </VStack>
        <VStack className="bg-grey">
          <HStack
            w="100%"
            padding="3rem 1rem"
            itemSpace="2rem"
            responsive="576px"
            rItemSpace="2rem"
            className="m-w-1140"
          >
            <VStack w="50%" responsive="576px" rw="100%">
              <Card className="w-100">
                <img
                  src={`${imgBaseUrl}/profile_pictures/${doctor.Picture}`}
                  alt={`Dr. ${doctor.Full_Name}`}
                />
              </Card>
            </VStack>
            <VStack w="50%" responsive="576px" rw="100%">
              <small>About Doctor</small>
              <Heading>Dr. {doctor.Full_Name}</Heading>
              {doctor.Intro_Summary && (
                <HStack padding="1rem 0">
                  <p>{doctor.Intro_Summary}</p>
                </HStack>
              )}
              <p>
                Go back to <strong>{doctor.Business_Name}</strong> site.
              </p>
            </VStack>
          </HStack>
        </VStack>
        <VStack padding="3rem 1rem" itemSpace="1.5rem" className="m-w-1140">
          <HeadingWrapper>
            <small>Patients Love Us</small>
            <Heading>Testimonials</Heading>
          </HeadingWrapper>
          <section>
            <CustomSwiper
              className="w-100"
              spaceBetween={20}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return '<span class="' + className + '"></span>';
                },
              }}
              autoHeight={true}
            >
              {testimonails &&
                testimonails.map((item, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="text-center">{item.Testimonial}</div>
                      <div className="text-center">
                        <strong>{item.Patient_Name}</strong>
                      </div>
                    </SwiperSlide>
                  );
                })}
              {/* <SwiperSlide>
                    <div className="text-center">
                    I referred my mother to Dr Patel because he is the BEST in
                      the industry for cosmetic dentistry and veneers. I get so
                      many compliments on my own smile/veneers (which they did for
                      me) so I knew my mom would be in impeccable hands with Tejas
                      and Kelly. The work they do is so meticulous, carefully
                      executed, &amp; meaningful and the experience is so
                      wonderful. They are literally the nicest, most genuine
                      people and I love going to see them! My mom's teeth came out
                      looking amazing as I knew they would. The best part is that
                      she is way more confident about her smile and that makes me
                      a happy daughter! :)
                    </div>
                  </SwiperSlide> */}
              <div className="swiper-pagination"></div>
            </CustomSwiper>
          </section>
        </VStack>
        <CTA>
          <VStack padding="4rem 1rem">
            <HeadingWrapper align="center">
              <Heading>See What's Possible</Heading>
              <PrimaryButton
                as="a"
                href={`${window.origin}/patient/login?doctorID=${doctor.Doctor_ID}`}
              >
                Get Started <ArrowRight />
              </PrimaryButton>
            </HeadingWrapper>
          </VStack>
        </CTA>
        <Footer />
      </VStack>
    );
  }
}

export default LandingPage;
