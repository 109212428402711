import React from "react";
import { X } from "react-feather";
import {
  OutlineButton,
  PrimaryButton,
} from "../styledComponents/GlobalElements";
import styled from "styled-components";

const ModalContainer = styled.div`
  backdrop-filter: blur(2px);
  background-color: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`;

const ModalDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;
  max-height: calc(100% - 0rem);
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  svg {
    cursor: pointer;
  }
`;
const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
`;
const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  button + button {
    margin-left: 1rem;
  }
`;

function Modal(props) {
  return (
    <ModalContainer>
      <ModalDialog>
        <ModalContent>
          <ModalHeader>
            <h3>{props.header}</h3>
            <X size="24" onClick={props.close} />
          </ModalHeader>
          <ModalBody>{props.children}</ModalBody>
          <ModalFooter>
            <OutlineButton onClick={props.close}>Close</OutlineButton>
            {props.saveHandler && (
              <PrimaryButton onClick={props.saveHandler}>Save</PrimaryButton>
            )}
            {props.downloadHandler && (
              <PrimaryButton onClick={props.downloadHandler}>
                Save &amp; Download
              </PrimaryButton>
            )}
          </ModalFooter>
        </ModalContent>
      </ModalDialog>
    </ModalContainer>
  );
}

export default Modal;
