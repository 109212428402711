import React, { useState, useEffect, useCallback, useRef } from "react";
import Axios from "axios";
import { Search, AlertCircle } from "react-feather";
import {
  Filter,
  Header,
  HStack,
  OutlineButton,
  SearchBar,
  SmallButton,
  PrimaryButton,
  VStack,
  ProgressBox,
} from "../../styledComponents/GlobalElements";
import { Table } from "../../styledComponents/TableElements";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";

function Dashboard() {
  const navigate = useNavigate();
  const [appointmentList, setAppointmentList] = useState([]);
  const [paginationLength, setPaginationLength] = useState(0); // total no of appointments specific to doctor. length
  const [limit, setLimit] = useState(10);

  const sortingRef = useRef();

  const redirectViewAppointment = ({ id }) => {
    navigate(`/doctor/viewAppointment`, { state: { appointmentID: id } });
  };

  const fetchAppointmentList = useCallback(
    (sorting) => {
      Axios({
        method: "POST",
        data: { limit, sorting },
        withCredentials: true,
        url: "/doctor/appointmentList",
      }).then((res) => {
        if (res.data.status) {
          setAppointmentList(res.data.result);
          setPaginationLength(res.data.length);
        } else {
          setAppointmentList([]);
          setPaginationLength(0);
        }
      });
    },
    [limit]
  );

  useEffect(() => {
    fetchAppointmentList();
  }, [limit, fetchAppointmentList]);

  const [percentage, setPercentage] = useState(0);
  const [pending, setPending] = useState({});

  useEffect(() => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/doctor/profile-progress",
    }).then((result) => {
      if (result.data) {
        setPercentage(result.data.percentage);
        setPending(result.data);
      }
    });
    // setInterval(() => setCompleted(Math.floor(Math.random() * 100) + 1), 2000);
  }, []);

  return (
    <VStack
      as="section"
      margin="1.5rem 0px"
      padding="1.5rem"
      itemSpace="1.5rem"
      className="m-w-1140"
    >
      {percentage < 100 ? (
        <ProgressBox>
          <h2>Welcome onboard!</h2>
          <p>Complete your profile and start accepting patient appointments.</p>
          <ul>
            {pending.picture === 1 ? (
              <li>
                <AlertCircle color="red" /> Profile Picture
              </li>
            ) : null}
            {pending.intro_summary === 1 ? (
              <li>
                <AlertCircle color="red" /> Intro Summary
              </li>
            ) : null}
            {pending.intro_video === 1 ? (
              <li>
                <AlertCircle color="red" /> Intro Video
              </li>
            ) : null}
            {pending.logo === 1 ? (
              <li>
                <AlertCircle color="red" /> Brand Logo
              </li>
            ) : null}
          </ul>
          <ProgressBar bgcolor={"#46ad2a"} completed={percentage} />
        </ProgressBox>
      ) : null}
      <Header>
        <h1>Dashboard</h1>
        <OutlineButton>Tech Support</OutlineButton>
      </Header>
      <HStack>
        <SearchBar htmlFor="dashboard-search">
          <input
            type="text"
            placeholder="Search"
            id="dashboard-search"
            onChange={(e) => {
              sortingRef.current = {
                ...sortingRef.current,
                ...{ searchString: e.target.value },
              };
              fetchAppointmentList(sortingRef.current);
            }}
          />
          <Search size={24} />
        </SearchBar>
        <Filter>
          <span>Lead Status</span>
          <select
            // id="updateStatus"
            onChange={(e) => {
              sortingRef.current = {
                ...sortingRef.current,
                ...{ sortBy: e.target.value },
              };
              fetchAppointmentList(sortingRef.current);
            }}
            defaultValue=""
          >
            <option value="">All Active</option>
            <optgroup label="Active">
              <option value="new">New</option>
              <option value="inContact">In Contact</option>
              <option value="scheduled">Scheduled</option>
            </optgroup>

            <optgroup label="Archived">
              <option value="noResponse">No Response</option>
              <option value="notInterested">Not Interested</option>
            </optgroup>
          </select>
        </Filter>
      </HStack>
      <VStack align="center" justify="center">
        <Table>
          <thead>
            <tr>
              <th scope="col">Patient Name</th>
              <th scope="col">Email Address</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Requested Date</th>
              <th scope="col">Video</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {appointmentList.length > 0 ? (
              appointmentList.map((patient, i) => {
                return (
                  <tr key={i}>
                    <td data-label="Patient Name">
                      <strong>{patient.FullName}</strong>
                    </td>
                    <td data-label="Email Address">{patient.Email}</td>
                    <td data-label="Phone Number">{patient.Phone}</td>
                    <td data-label="Requested Date">
                      {new Date(patient.Datetime).toLocaleDateString("en-US")}
                    </td>
                    <td data-label="Video">
                      {patient.Doctor_Video ? "Sent" : "Not Sent"}
                    </td>
                    <td>
                      <SmallButton
                        onClick={() =>
                          redirectViewAppointment({
                            id: patient.Appointment_ID,
                          })
                        }
                      >
                        View Profile
                      </SmallButton>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">
                  <strong>No Appointments</strong>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {limit >= paginationLength ? null : (
          <PrimaryButton
            disabled={limit >= paginationLength ? true : false}
            onClick={() => {
              if (limit < paginationLength) {
                setLimit(limit + 10);
              }
            }}
          >
            View More <i className="fas fa-chevron-down"></i>
          </PrimaryButton>
        )}
      </VStack>
    </VStack>
  );
}

export default Dashboard;
