import React, { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { GlobalContext } from "./context/GlobalContextApi";

const ProtectedRoute = ({ component: Component, role }) => {
  const location = useLocation();
  const { user, checkUserLogin } = useContext(GlobalContext);

  useEffect(() => {
    checkUserLogin();
  }, [checkUserLogin]);

  return user && user.Role === role ? (
    <Component />
  ) : (
    (user === false || (user && user.Role !== role)) && (
      <Navigate
        to={`/${role.toLowerCase()}/login`}
        replace
        state={{ location }}
      />
    )
  );
};

export default ProtectedRoute;
