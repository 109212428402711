import React, { useContext } from "react";
import styled from "styled-components";
import { HStack, Card } from "../../styledComponents/GlobalElements";
import { GlobalContext } from "../context/GlobalContextApi";

// const RowCols = styled.div`
//   display: grid;
//   grid-gap: 1rem 1rem;
//   grid-template-columns: auto auto auto;
// `;
const RowCols = styled(HStack)`
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  & > div {
    flex: 0 0 auto;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    @media (min-width: 576px) {
      width: 50%;
    }
    /* @media (min-width: 768px) {
      width: 33.3333%;
    }
    @media (min-width: 992px) {
      width: 25%;
    }
    @media (min-width: 1400px) {
      width: 16.6666%;
    } */
  }
`;

function SelectCaseDisplay({ images }) {
  const { imgBaseUrl } = useContext(GlobalContext);
  return (
    <RowCols>
      {images.length ? (
        images.map((image, i) => {
          return (
            <div key={i}>
              <Card>
                <img
                  key={i}
                  src={`${imgBaseUrl}/case_images/${image.Path}`}
                  alt="CaseImage"
                />
              </Card>
            </div>
          );
        })
      ) : (
        <span>No Images</span>
      )}
    </RowCols>
  );
}

export default SelectCaseDisplay;
