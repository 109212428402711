import React from "react";
import Axios from "axios";
import {
  HStack,
  OrderedList,
  VStack,
  InputText,
  ErrorMessage,
  InputField,
  PrimaryButton,
  Textarea,
  OutlineButton,
} from "../../../styledComponents/GlobalElements";
import { useFormValidation } from "../../../globalComponents/validation/useFormValidation";
import { validationStep2 } from "../../../globalComponents/validation/validationConstraints";
import { useNavigate } from "react-router-dom";

var formLoopData = {};

function Step2({ setCount, formFieldsRef }) {
  const navigate = useNavigate();

  const { handleValidation, errors } = useFormValidation({
    data: formLoopData,
    validations: validationStep2,
  });

  const handleRegister = () => {
    Axios({
      method: "POST",
      data: formFieldsRef.current,
      withCredentials: true,
      url: "/doctor/register",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.data.status) {
        navigate("/doctor/thank-you");
      }
    });
  };

  const handleDataForValidation = () => {
    var data = new FormData(document.getElementById("register-form"));

    for (var [key, value] of data) {
      formLoopData[key] = value;
    }

    handleValidation(() => {
      // Callback fn runs on no errors
      formFieldsRef.current = {
        ...formFieldsRef.current,
        ...formLoopData,
      };
      handleRegister();
    });
  };
  const handleSaveDataBackButton = () => {
    formFieldsRef.current = {
      ...formFieldsRef.current,
      ...formLoopData,
    };
    setCount((prevCount) => prevCount - 1);
  };

  return (
    <>
      <HStack w="100%" h="100%" responsive="576px">
        {/* left section */}
        <VStack
          w="50%"
          className="bg-lightGrey"
          padding="5rem"
          align="center"
          justify="center"
          responsive="576px"
          rw="100%"
        >
          <p>Step 2 of 2</p>
          <React.Fragment>
            <h1 className="text-center">MendSmile Software</h1>
            <OrderedList>
              <li>Today's payment</li>
              <li>
                <span>Software Price </span>
                <em>Free</em>
              </li>
              <li>Future payments</li>
              <li>
                <span>Monthly payments </span>
                <em>Free</em>
              </li>
            </OrderedList>
          </React.Fragment>
        </VStack>
        {/* right section */}
        <VStack
          w="50%"
          as="form"
          id="register-form"
          padding="1rem"
          align="center"
          justify="center"
          itemSpace="1rem"
          responsive="576px"
          rw="100%"
        >
          <InputField>
            <Textarea
              className={errors.address ? "error" : ""}
              required
              type="text"
              name="address"
              rows="3"
              defaultValue={formFieldsRef.current.address}
              placeholder="Your street address"
            ></Textarea>
            {errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
          </InputField>
          <HStack
            itemSpace="2rem"
            w="100%"
            responsive="576px"
            rItemSpace="1rem"
          >
            <InputField>
              <InputText
                className={errors.city ? "error" : ""}
                required
                placeholder="Your City"
                type="text"
                name="city"
                defaultValue={formFieldsRef.current.city}
              />
              {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
            </InputField>
            <InputField>
              <InputText
                className={errors.state ? "error" : ""}
                required
                placeholder="State"
                type="text"
                name="state"
                defaultValue={formFieldsRef.current.state}
              />
              {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
            </InputField>
          </HStack>
          <HStack
            itemSpace="2rem"
            w="100%"
            responsive="576px"
            rItemSpace="1rem"
          >
            <InputField>
              <InputText
                className={errors.country ? "error" : ""}
                required
                placeholder="Country"
                type="text"
                name="country"
                defaultValue={formFieldsRef.current.country}
              />
              {errors.country && <ErrorMessage>{errors.country}</ErrorMessage>}
            </InputField>
            <InputField>
              <InputText
                className={errors.postalCode ? "error" : ""}
                required
                placeholder="Postal Code"
                type="text"
                name="postalCode"
                defaultValue={formFieldsRef.current.postalCode}
              />
              {errors.postalCode && (
                <ErrorMessage>{errors.postalCode}</ErrorMessage>
              )}
            </InputField>
          </HStack>
          <InputField>
            <InputText
              className={errors.practiceName ? "error" : ""}
              required
              placeholder="Practice Name"
              type="text"
              name="practiceName"
              defaultValue={formFieldsRef.current.practiceName}
            />
            {errors.practiceName && (
              <ErrorMessage>{errors.practiceName}</ErrorMessage>
            )}
          </InputField>
          <InputField>
            <InputText
              placeholder="Referral Name"
              type="text"
              name="referral"
              defaultValue={formFieldsRef.current.referral}
            />
          </InputField>
          <small>
            By clicking "Purchase," you are agreeing to our User Agreement and
            Privacy Policy.
          </small>
          <HStack itemSpace="2rem" w="100%">
            <OutlineButton
              type="button"
              onClick={() => {
                handleSaveDataBackButton();
              }}
            >
              Back
            </OutlineButton>
            <PrimaryButton
              className="btn btn-primary ml-auto"
              type="button"
              onClick={() => {
                handleDataForValidation();
              }}
            >
              Purchase
            </PrimaryButton>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
}

export default Step2;
