import React, { useState, useRef } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  InputText,
  PrimaryButton,
  VStack,
} from "../../styledComponents/GlobalElements";

function Login() {
  const navigate = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();
  const [errorMsg, setErrorMsg] = useState("");

  const handleLogin = () => {
    Axios({
      method: "POST",
      data: {
        username: emailRef.current.value,
        password: passwordRef.current.value,
      },
      withCredentials: true,
      url: "/doctor/login",
    }).then((res) => {
      if (res.data.info.status) {
        navigate("/doctor/dashboard");
      } else {
        setErrorMsg(res.data.info.message);
      }
    });
  };

  return (
    <VStack w="100%" h="100%" align="center" justify="center">
      <VStack align="center" justify="center" className="login-box">
        <p>Welcome to Mendsmile</p>
        <VStack itemSpace="2rem" align="center" justify="center">
          <h1 className="text-center">Doctor Login</h1>
          {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
          <InputText
            type="email"
            placeholder="Email Address"
            ref={emailRef}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                console.log("key up");
                e.preventDefault();
                passwordRef.current.focus();
              }
            }}
          />
          <InputText
            as="input"
            type="password"
            placeholder="Password"
            ref={passwordRef}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                console.log("key up");
                e.preventDefault();
                handleLogin();
              }
            }}
          />
          <PrimaryButton
            onClick={() => {
              handleLogin();
            }}
          >
            Login
          </PrimaryButton>
        </VStack>
      </VStack>
    </VStack>
  );
}

export default Login;
