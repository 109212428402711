import React, { useContext, useRef, useState } from "react";
import Axios from "axios";
import Modal from "../../globalComponents/Modal";
import {
  Header,
  Video,
  Heading,
  Line,
  Card,
  VStack,
  HStack,
  RecordControls,
  CameraVideo,
  RecordIcon,
} from "../../styledComponents/GlobalElements";
import { useLocation, useNavigate } from "react-router-dom";
import { Circle, Mail, Pause, Phone, Play, Square } from "react-feather";
import SelectCaseDisplay from "../../globalComponents/viewAppointmentComponents/SelectCaseDisplay";
import YourRecommendation from "../../globalComponents/viewAppointmentComponents/YourRecommendation";
import { GlobalContext } from "../../globalComponents/context/GlobalContextApi";

function RecordingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const appointmentRef = useRef(location.state.appointment);
  const patientsPicsRef = useRef(location.state.patientsPics);
  const suggestedTreatmentRef = useRef(location.state.suggestedTreatment);
  const requiredAppointmentsRef = useRef(location.state.requiredAppointments);
  const investmentRef = useRef(location.state.investment);
  const caseMediaRef = useRef(location.state.caseMedia);

  const { imgBaseUrl, setSnackbarMessage } = useContext(GlobalContext);

  const mediaStreamRef = useRef();
  const recorderRef = useRef();
  const recordedChunksRef = useRef([]);

  const [recordedChunks, setRecordedChunks] = useState();

  const [recordActive, setRecordActive] = useState(false);
  const [recordPause, setRecordPause] = useState(false);

  const doctorVideoRef = useRef();

  const [modalActive, setModalActive] = useState(false);

  const constraints = { audio: true, video: { facingMode: "user" } };

  const doctorVideoHandler = () => {
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      doctorVideoRef.current = stream;
      document.getElementById("doctorVideo").srcObject = stream;
    });
  };

  const recorderStartHandler = () => {
    if (!recordActive) {
      var options = { mimeType: "video/webm; codecs=vp9" };
      navigator.mediaDevices
        .getDisplayMedia({
          audio: true,
          video: { width: 1280, height: 720 },
        })
        .then((stream) => {
          doctorVideoHandler();
          mediaStreamRef.current = stream;
          recorderRef.current = new MediaRecorder(stream, options);
          recorderRef.current.ondataavailable = handleDataAvailable;
          recorderRef.current.start();
          setRecordActive(true);
        });
    } else {
      doctorVideoRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      recorderRef.current.stop();
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      setModalActive(true);
      setRecordActive(false);
    }
  };
  const recorderPauseHandler = () => {
    if (!recordPause) {
      recorderRef.current.pause();
      setRecordPause(true);
    } else {
      recorderRef.current.resume();
      setRecordPause(false);
    }
  };
  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      recordedChunksRef.current.push(event.data);
      setRecordedChunks(
        window.URL.createObjectURL(
          new Blob([event.data], {
            type: "video/webm",
          })
        )
      );
    } else {
      console.log("Error in collecting recorded data");
    }
  };
  const download = () => {
    var blob = new Blob(recordedChunksRef.current, {
      type: "video/webm",
    });
    var url = URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = "test.webm";
    a.click();
    window.URL.revokeObjectURL(url);
    setModalActive(false);
    recordedChunksRef.current = [];
  };
  const modalToggleHandler = () => {
    setModalActive(!modalActive);
  };
  const saveRecordedVideo = () => {
    var blob = new Blob(recordedChunksRef.current, {
      type: "video/webm",
    });
    var formData = new FormData();
    formData.append("doctor_video", blob, "video.webm");
    formData.append("appointment_id", appointmentRef.current.Appointment_ID);
    // axios
    Axios({
      method: "POST",
      withCredentials: true,
      data: formData,
      url: "/doctor/uploadRecodedVideo",
    }).then((res) => {
      if (res) {
        recordedChunksRef.current = [];
        setModalActive(false);
        setSnackbarMessage(res.data.msg);
        navigate("/doctor/dashboard");
      } else {
        console.log("Error in saving video");
      }
    });
  };

  return (
    <>
      <VStack as="section">
        <VStack padding="1.5rem 1.5rem 0 1.5rem" className="m-w-1140">
          <Header>
            <h1>{appointmentRef.current.Patient_Name}</h1>
          </Header>
        </VStack>
        <HStack
          itemSpace="1.5rem"
          padding="1.5rem"
          responsive="576px"
          rItemSpace="1.5rem"
          className="m-w-1140"
          w="100%"
        >
          <VStack w="50%" responsive="576px" rw="100%">
            <Card>
              <div className="card-header">Selfie</div>
              <img
                src={`${imgBaseUrl}/patient_pictures/${patientsPicsRef.current.selfie}`}
                alt="Selfie"
              />
            </Card>
          </VStack>
          <VStack w="50%" responsive="576px" rw="100%">
            <Card>
              <div className="card-header">Close-up</div>
              <img
                src={`${imgBaseUrl}/patient_pictures/${patientsPicsRef.current.closeup}`}
                alt="Close-up"
              />
            </Card>
          </VStack>
        </HStack>
        <VStack className="bg-grey" padding="3rem 0">
          <VStack padding="0 1.5rem" align="center" className="m-w-1140">
            <Heading className="text-center">
              What you would like to change about your smile?
            </Heading>

            <p>{appointmentRef.current.Concern}</p>
          </VStack>
        </VStack>
        <VStack padding="1.5rem" itemSpace="1.5rem" className="m-w-1140">
          <HStack itemSpace="1.5rem" responsive="576px" rItemSpace="1.5rem">
            <VStack w="50%" responsive="576px" rw="100%">
              <Card className="w-100">
                <img
                  src={`${imgBaseUrl}/profile_pictures/${appointmentRef.current.Doctor_Picture}`}
                  alt="ProfilePicture"
                />
              </Card>
            </VStack>
            <VStack w="50%" responsive="576px" rw="100%">
              <small>About Doctor</small>
              <Heading>{appointmentRef.current.Doctor_Name}</Heading>
              {appointmentRef.current.Intro_Summary && (
                <VStack padding="py-3">
                  <p>{appointmentRef.current.Intro_Summary}</p>
                </VStack>
              )}
              <p>
                <Mail size={24} /> {appointmentRef.current.Doctor_Email}
              </p>
              <p>
                <Phone size={24} /> {appointmentRef.current.Doctor_Phone}
              </p>
            </VStack>
          </HStack>
        </VStack>
        <VStack padding="0 1.5rem" className="m-w-1140">
          <Line></Line>
        </VStack>
        <VStack padding="1.5rem" className="m-w-1140">
          <VStack align="center">
            <small>Case Studies</small>
            <h2>Smile Makeovers</h2>
          </VStack>
          <SelectCaseDisplay images={caseMediaRef.current} />
        </VStack>
        <VStack padding="0 1.5rem" className="m-w-1140">
          <Line></Line>
        </VStack>
        <VStack padding="1.5rem" itemSpace="1.5rem" className="m-w-1140">
          <VStack align="center">
            <small>Alternate Text</small>
            <h2>Recommendations</h2>
          </VStack>
          {(suggestedTreatmentRef.current ||
            requiredAppointmentsRef.current ||
            investmentRef.current) && (
            <YourRecommendation
              edit={false}
              recommendations={{
                suggestedTreatment: suggestedTreatmentRef.current,
                requiredAppointments: requiredAppointmentsRef.current,
                investment: investmentRef.current,
              }}
            />
          )}
        </VStack>
        <RecordControls recordActive={recordActive} id="recorderWrapper">
          <RecordIcon onClick={recorderStartHandler}>
            {!recordActive ? (
              <Circle size={20} color="red" fill="red" />
            ) : (
              <Square size={20} color="red" fill="red" />
            )}
          </RecordIcon>
          {recordActive && (
            <RecordIcon onClick={recorderPauseHandler}>
              {!recordPause ? (
                <Pause size={20} fill="black" />
              ) : (
                <Play size={20} fill="black" />
              )}
            </RecordIcon>
          )}
        </RecordControls>
        {recordActive && (
          <CameraVideo>
            <Video id="doctorVideo" autoPlay controls></Video>
          </CameraVideo>
        )}
      </VStack>
      {modalActive && (
        <Modal
          close={modalToggleHandler}
          header={"Preview"}
          saveHandler={saveRecordedVideo}
          downloadHandler={download}
        >
          <Video src={recordedChunks} controls autoPlay playsInline></Video>
        </Modal>
      )}
    </>
  );
}

export default RecordingPage;
