import React from "react";
import { HStack } from "../../styledComponents/GlobalElements";

import styled from "styled-components";

export const Copyright = styled.div`
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    text-align: center;
  }
`;

export const Links = styled.ul`
  display: flex;
  flex-direction: row;
  @media (max-width: 575.98px) {
    width: 100%;
  }
  li {
    padding: 0 10px;
    @media (max-width: 575.98px) {
      width: 100%;
      text-align: center;
    }
  }
`;
function Footer() {
  return (
    <HStack
      as="section"
      className="bg-black c-white"
      padding="1rem"
      align="center"
      justify="space-between"
      responsive="576px"
      rItemSpace="1rem"
    >
      <Copyright>© 2021 MendSmile LLC</Copyright>
      <Links>
        <li>About MendSmile</li>
        <li>Privacy Policy</li>
        <li>User Agreement</li>
      </Links>
    </HStack>
  );
}

export default Footer;
