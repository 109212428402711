import React, { useRef, useState } from "react";
import Axios from "axios";
import {
  ErrorMessage,
  InputText,
  PrimaryButton,
  VStack,
} from "../../../styledComponents/GlobalElements";
import { useNavigate, Link } from "react-router-dom";
import { ArrowRight } from "react-feather";

function PasswordStep({ doctorID, usernameRef }) {
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");
  const passwordRef = useRef();

  const hangleLogin = () => {
    Axios({
      method: "POST",
      data: {
        username: usernameRef.current,
        password: passwordRef.current.value,
      },
      withCredentials: true,
      url: "/patient/login",
    }).then((res) => {
      if (res.data.info.status) {
        if (doctorID) {
          navigate(`/patient/register`, {
            state: {
              doctorID,
              userExists: true,
            },
          });
        } else {
          navigate("/patient/dashboard");
        }
      } else {
        setErrorMsg(res.data.info.message);
      }
    });
  };

  return (
    <>
      <h1 className="text-center">Welcome Back</h1>
      {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      <VStack>
        <InputText
          type="password"
          placeholder="Password"
          ref={passwordRef}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              console.log("key up");
              e.preventDefault();
              if (passwordRef.current.value) {
                hangleLogin();
              } else {
                setErrorMsg("Enter password");
              }
            }
          }}
        />
        <small>
          <Link to="/patient/register">Forgot Password? </Link>
        </small>
      </VStack>
      <PrimaryButton
        type="button"
        onClick={(e) => {
          //   e.preventDefault();
          if (passwordRef.current.value) {
            hangleLogin();
          } else {
            setErrorMsg("Enter password");
          }
        }}
      >
        Next <ArrowRight size={20} />
      </PrimaryButton>
    </>
  );
}

export default PasswordStep;
