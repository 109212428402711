import React, { createContext, useCallback, useState } from "react";
import Axios from "axios";

export const GlobalContext = createContext();

function GlobalContextApi(props) {
  const [user, setUser] = useState(null);
  const [registerAccess, setRegisterAccess] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // let imgBaseUrl = "http://localhost:4000/images/uploads";
  let imgBaseUrl = "https://api.mendsmile.com/images/uploads";

  const checkUserLogin = useCallback(async () => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "/checkUserLogin",
    }).then((res) => {
      if (res.data.status) {
        setUser(res.data.user);
      } else {
        setUser(false);
      }
      console.log("check login finish");
    });
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        imgBaseUrl,
        user,
        checkUserLogin,
        registerAccess,
        setRegisterAccess,
        snackbarMessage,
        setSnackbarMessage,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}

export default GlobalContextApi;
