import React, { useState, useCallback } from "react";
import {
  HStack,
  VStack,
  InputText,
  ErrorMessage,
  InputField,
  OutlineButton,
  PrimaryButton,
  CustomFileUpload,
} from "../../../styledComponents/GlobalElements";
import Axios from "axios";
import { Camera } from "react-feather";
import { useFormValidation } from "../../../globalComponents/validation/useFormValidation";
import { patientRegValidationStep3 } from "../../../globalComponents/validation/validationConstraints";
import { ReactComponent as AlmostDone } from "../../../assets/images/artwork/almost-done.svg";
import { useNavigate } from "react-router-dom";

var formLoopData = {};

function Step3({ setCount, formFieldsRef }) {
  const navigate = useNavigate();
  const [closeup, setcloseup] = useState({
    file: [],
    filepreview: null,
  });
  const [selfie, setselfie] = useState({
    file: [],
    filepreview: null,
  });

  const { handleValidation, errors } = useFormValidation({
    data: formLoopData,
    validations: patientRegValidationStep3,
  });

  const handleRegister = useCallback(
    (data) => {
      Axios({
        method: "POST",
        data: data,
        withCredentials: true,
        url: "/patient/register",
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        if (res.data.status) {
          navigate("/patient/thankyou");
        }
      });
    },
    [navigate]
  );

  const updateForm = useCallback(() => {
    var data = new FormData(document.getElementById("register-form"));

    for (var [key, value] of data) {
      formLoopData[key] = value;
    }
    formFieldsRef.current = {
      ...formFieldsRef.current,
      ...formLoopData,
    };
  }, [formFieldsRef]);

  const handleDataForValidation = useCallback(async () => {
    updateForm();

    handleValidation(() => {
      // Callback fn runs on no errors
      var data = new FormData(document.getElementById("register-form"));

      Object.entries(formFieldsRef.current).forEach(([key, value]) => {
        if (key === "closeup" || key === "selfie" || key === "referralSource") {
          data.set(key, value);
        } else {
          data.append(key, value);
        }
      });
      handleRegister(data);
    });
  }, [formFieldsRef, handleValidation, handleRegister, updateForm]);

  const handleSaveDataBackButton = () => {
    updateForm();

    setCount((prevCount) => prevCount - 1);
  };

  const previewImage = (event) => {
    if (event.target.name === "closeup") {
      setcloseup({
        ...setcloseup,
        file: event.target.files[0],
        filepreview: URL.createObjectURL(event.target.files[0]),
      });
      //   updateForm();
    }
    if (event.target.name === "selfie") {
      setselfie({
        ...setselfie,
        file: event.target.files[0],
        filepreview: URL.createObjectURL(event.target.files[0]),
      });
      //   updateForm();
    }
  };

  return (
    <>
      <HStack w="100%" h="100%" responsive="576px">
        {/* left section */}
        <VStack
          w="50%"
          className="bg-lightGrey"
          padding="5rem"
          align="center"
          justify="center"
          responsive="576px"
          rw="100%"
        >
          <p>Step 3 of 3</p>
          <React.Fragment>
            <h1 className="text-center">
              Upload a close-up of your smile. And we are done!
            </h1>
            <AlmostDone />
          </React.Fragment>
        </VStack>
        {/* right section */}
        <VStack
          w="50%"
          as="form"
          id="register-form"
          padding="1rem"
          align="center"
          justify="center"
          itemSpace="1rem"
          responsive="576px"
          rw="100%"
        >
          <InputField>
            <CustomFileUpload>
              {closeup.filepreview !== null ? (
                <img src={closeup.filepreview} alt="UploadImage" />
              ) : null}
              <label htmlFor="closeup">
                <span>
                  Upload a Close-Up<sup>*</sup>
                </span>
                <Camera />
                <input
                  className={errors.closeup ? "error" : ""}
                  required
                  type="file"
                  name="closeup"
                  accept="image/*"
                  onChange={previewImage}
                  id="closeup"
                />
              </label>
            </CustomFileUpload>
            {errors.closeup && <ErrorMessage>{errors.closeup}</ErrorMessage>}
          </InputField>
          <InputField>
            <CustomFileUpload>
              {selfie.filepreview !== null ? (
                <img src={selfie.filepreview} alt="UploadImage" />
              ) : null}
              <label htmlFor="selfie">
                <span>
                  Upload a Selfie<sup>*</sup>
                </span>
                <Camera />
                <input
                  className={errors.selfie ? "error" : ""}
                  required
                  type="file"
                  name="selfie"
                  accept="image/*"
                  onChange={previewImage}
                  id="selfie"
                />
              </label>
            </CustomFileUpload>
            {errors.selfie && <ErrorMessage>{errors.selfie}</ErrorMessage>}
          </InputField>
          <InputField>
            <InputText
              className={errors.referralSource ? "error" : ""}
              required
              placeholder="How did you hear about us?"
              type="text"
              name="referralSource"
              defaultValue={formFieldsRef.current.referralSource}
            />
            {errors.referralSource && (
              <ErrorMessage>{errors.referralSource}</ErrorMessage>
            )}
          </InputField>
          <InputField>
            <small>
              By clicking "Submit," you are agreeing to our User Agreement and
              Privacy Policy.
            </small>
          </InputField>
          <HStack w="100%" justify="space-between">
            <OutlineButton
              className="btn btn-primary"
              type="button"
              onClick={() => {
                handleSaveDataBackButton();
              }}
            >
              Back
            </OutlineButton>
            <PrimaryButton
              className="btn btn-primary ml-auto"
              type="button"
              onClick={() => {
                handleDataForValidation();
              }}
            >
              Submit
            </PrimaryButton>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
}

export default Step3;
