import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { Link, useParams } from "react-router-dom";
import { VStack } from "../../styledComponents/GlobalElements";
import { ReactComponent as Celebration } from "../../assets/images/artwork/celebration.svg";

function Confirm() {
  const [isLoading, setLoading] = useState(true);
  let { id } = useParams();
  const [validation, setValidation] = useState(false);
  const checkCode = useCallback(() => {
    Axios({
      method: "POST",
      data: { code: id },
      withCredentials: true,
      url: "/doctor/verify/",
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status === true) {
          setValidation(true);
          setLoading(false);
        } else {
          setValidation(false);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    checkCode();
  }, [checkCode]);

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  if (validation) {
    return (
      <VStack w="100%" h="100%" align="center" justify="center" padding="1rem">
        <Celebration />
        <h2 className="text-center">Your MendSmile account is now verified!</h2>
        <p>
          To login to your account <Link to="/doctor/login">click here</Link>
        </p>
      </VStack>
    );
  } else {
    return (
      <VStack w="100%" h="100%" align="center" justify="center" padding="1rem">
        <Celebration />
        <h2 className="text-center">
          Looks like your account is already verified!
        </h2>
        <p>
          To login to your account <Link to="/doctor/login">click here</Link>
        </p>
      </VStack>
    );
  }
}

export default Confirm;
