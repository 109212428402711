export const validationStep1 = {
  // Step 1 constraints
  firstName: {
    required: {
      value: true,
      message: "First Name required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid First Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Last Name required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid Last Name",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Gender required",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email required",
    },
    pattern: {
      value: "\\S+@\\S+\\.\\S+",
      message: "Email address is invalid",
    },
  },
  phone: {
    required: {
      value: true,
      message: "Phone required",
    },
    pattern: {
      value:
        "^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$",
      message: "Phone address is invalid",
    },
  },
  businessName: {
    required: {
      value: true,
      message: "Business Name is required",
    },
  },
  services: {
    custom: {
      isValid: (value) => JSON.parse(value).length,
      message: "Services required",
    },
  },
  password: {
    required: {
      value: true,
      message: "Password is required",
    },
    custom: {
      isValid: (value) => value.length > 6,
      message: "Password needs to be 6 characters or more",
    },
  },
};

export const validationStep2 = {
  // Step 2 constraints
  address: {
    required: {
      value: true,
      message: "Address required",
    },
  },
  city: {
    required: {
      value: true,
      message: "City required",
    },
  },
  state: {
    required: {
      value: true,
      message: "State required",
    },
  },
  country: {
    required: {
      value: true,
      message: "Country required",
    },
  },
  postalCode: {
    required: {
      value: true,
      message: "Postal Code required",
    },
  },
  practiceName: {
    required: {
      value: true,
      message: "Practice Name required",
    },
  },
};

export const patientRegValidationStep1 = {
  // Step 1 constraints
  firstName: {
    required: {
      value: true,
      message: "First Name required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid First Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Last Name required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid Last Name",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Gender required",
    },
  },
  email: {
    required: {
      value: true,
      message: "Email required",
    },
    pattern: {
      value: "\\S+@\\S+\\.\\S+",
      message: "Email address is invalid",
    },
  },
  phone: {
    required: {
      value: true,
      message: "Phone required",
    },
    pattern: {
      value:
        "^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$",
      message: "Phone address is invalid",
    },
  },
  postalCode: {
    required: {
      value: true,
      message: "Postal Code required",
    },
  },
  password: {
    required: {
      value: true,
      message: "Password is required",
    },
    custom: {
      isValid: (value) => value.length > 6,
      message: "Password needs to be 6 characters or more",
    },
  },
};

export const patientRegValidationStep2 = {
  concern: {
    required: {
      value: true,
      message: "Concern required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid concern",
    },
  },
  services: {
    custom: {
      isValid: (value) => JSON.parse(value).length,
      message: "Services required",
    },
  },
  improve: {
    custom: {
      isValid: (value) => value.length > 0,
      message: "Improve required",
    },
  },
  missingTeeth: {
    required: {
      value: true,
      message: "Missing teeth required",
    },
  },
};

export const patientRegValidationStep3 = {
  selfie: {
    custom: {
      isValid: (value) => value.size > 0,
      message: "Selfie is required",
    },
  },
  closeup: {
    custom: {
      isValid: (value) => value.size > 0,
      message: "Close-up is required",
    },
  },
};

export const accountInfoValidation = {
  firstName: {
    required: {
      value: true,
      message: "First Name required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid First Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Last Name required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid Last Name",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Gender required",
    },
  },
  age: {
    required: {
      value: true,
      message: "Age required",
    },
  },
  state: {
    required: {
      value: true,
      message: "State required",
    },
  },
  city: {
    required: {
      value: true,
      message: "City required",
    },
  },
  postalCode: {
    required: {
      value: true,
      message: "Postal Code required",
    },
  },
};
export const accountPasswordValidation = {
  password: {
    required: {
      value: true,
      message: "Password is required",
    },
    custom: {
      isValid: (value) => value.length > 6,
      message: "Password needs to be 6 characters or more",
    },
  },
};

// Doctor
export const doctorAccInfoValidations = {
  // Step 1 constraints
  firstName: {
    required: {
      value: true,
      message: "First Name required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid First Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Last Name required",
    },
    pattern: {
      value: "^[A-Za-z]+",
      message: "Enter a valid Last Name",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Gender required",
    },
  },
  introSummary: {
    required: {
      value: true,
      message: "Intro Summary required",
    },
  },
};
