import React from "react";
import { FlexContainer } from "../styledComponents/GlobalElements";

export default function Loader() {
  return (
    <FlexContainer>
      <p>Loading...</p>
    </FlexContainer>
  );
}
