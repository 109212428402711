import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import {
  HStack,
  VStack,
  InputText,
  Textarea,
  ErrorMessage,
  InputField,
  CustomRadio,
  CustomCheckbox,
  PrimaryButton,
  OutlineButton,
} from "../../../styledComponents/GlobalElements";
import MultiSelectDropdown from "../../../globalComponents/multi-select-dropdown/MultiSelectDropdown";
import { ArrowRight } from "react-feather";
import { ReactComponent as KnowBetter } from "../../../assets/images/artwork/know-better.svg";
import { useFormValidation } from "../../../globalComponents/validation/useFormValidation";
import { patientRegValidationStep2 } from "../../../globalComponents/validation/validationConstraints";

var formLoopData = {};

function Step2({ setCount, formFieldsRef, userExists }) {
  const [services, setServices] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const { handleValidation, errors } = useFormValidation({
    data: formLoopData,
    validations: patientRegValidationStep2,
  });

  const updateForm = useCallback(() => {
    var data = new FormData(document.getElementById("register-form"));
    for (var [key, value] of data) {
      formLoopData[key] = value;
    }
    formLoopData["services"] = JSON.stringify(selectedValues.map((i) => i.id));
    formLoopData["improve"] = data.getAll("improve");
    formFieldsRef.current = {
      ...formFieldsRef.current,
      ...formLoopData,
    };
  }, [formFieldsRef, selectedValues]);

  const handleSaveDataBackButton = () => {
    updateForm();
    setCount((prevCount) => prevCount - 1);
  };

  const handleDataForValidation = useCallback(async () => {
    updateForm();
    handleValidation(() => {
      // Callback fn runs on no errors

      formFieldsRef.current = {
        ...formFieldsRef.current,
        ...formLoopData,
      };
      setCount((prevCount) => prevCount + 1);
    });
  }, [formFieldsRef, handleValidation, setCount, updateForm]);

  const fetchServices = useCallback(async () => {
    await Axios({
      method: "POST",
      data: { doctorID: formFieldsRef.current.doctorID },
      withCredentials: true,
      url: "/patient/services",
    }).then((res) => {
      if (res.data.status) {
        let addService = [];
        Object.entries(res.data.result).forEach(([key, value]) => {
          addService.push({ id: value.Service_ID, label: value.Name });
        });
        setServices(addService);
        if (formFieldsRef.current.services) {
          setSelectedValues(
            JSON.parse(formFieldsRef.current.services).map((i) =>
              addService.find((ele) => ele.id === i)
            )
          );
        }
      }
    });
  }, [formFieldsRef]);

  useEffect(() => {
    fetchServices();
    return () => {};
  }, [fetchServices]);

  return (
    <>
      <HStack w="100%" h="100%" responsive="576px">
        {/* left section */}
        <VStack
          w="50%"
          className="bg-lightGrey"
          padding="5rem"
          align="center"
          justify="center"
          responsive="576px"
          rw="100%"
        >
          <p>Step 2 of 3</p>
          <React.Fragment>
            <h1 className="text-center">
              Now let's get to know you a little bit better!
            </h1>
            <KnowBetter />
          </React.Fragment>
        </VStack>
        {/* right section */}
        <VStack
          w="50%"
          as="form"
          id="register-form"
          padding="1rem"
          align="center"
          justify="center"
          itemSpace="1rem"
          responsive="576px"
          rw="100%"
        >
          <InputField>
            <Textarea
              className={errors.concern ? "error" : ""}
              required
              type="text"
              name="concern"
              rows="4"
              defaultValue={formFieldsRef.current.concern}
              placeholder="Tell me your concerns, what you are hoping to achieve, and what you'd like me to address in your virtual consult"
            ></Textarea>
            {errors.concern && <ErrorMessage>{errors.concern}</ErrorMessage>}
          </InputField>
          <VStack w="100%">
            <p>What would you like to improve about your smile?</p>
            <HStack w="100%" className="of-auto" itemSpace="1rem">
              <CustomCheckbox htmlFor="color">
                Color
                <input
                  required
                  id="color"
                  type="checkbox"
                  name="improve"
                  value="color"
                  defaultChecked={
                    formFieldsRef.current.improve &&
                    formFieldsRef.current.improve.find((i) => i === "color")
                      ? true
                      : null
                  }
                  defaultValue={formFieldsRef.current.color}
                />
                <span className="checkmark"></span>
              </CustomCheckbox>
              <CustomCheckbox htmlFor="size">
                Size
                <input
                  required
                  id="size"
                  type="checkbox"
                  name="improve"
                  value="size"
                  defaultChecked={
                    formFieldsRef.current.improve &&
                    formFieldsRef.current.improve.find((i) => i === "size")
                      ? true
                      : null
                  }
                  defaultValue={formFieldsRef.current.size}
                />
                <span
                  className={errors.improve ? "error checkmark" : "checkmark"}
                ></span>
              </CustomCheckbox>
              <CustomCheckbox htmlFor="alignment">
                Alignment
                <input
                  required
                  id="alignment"
                  type="checkbox"
                  name="improve"
                  value="alignment"
                  defaultChecked={
                    formFieldsRef.current.improve &&
                    formFieldsRef.current.improve.find((i) => i === "alignment")
                      ? true
                      : null
                  }
                  defaultValue={formFieldsRef.current.alignment}
                />
                <span
                  className={errors.improve ? "error checkmark" : "checkmark"}
                ></span>
              </CustomCheckbox>
              <CustomCheckbox htmlFor="shape">
                Shape
                <input
                  required
                  id="shape"
                  type="checkbox"
                  name="improve"
                  value="shape"
                  defaultChecked={
                    formFieldsRef.current.improve &&
                    formFieldsRef.current.improve.find((i) => i === "shape")
                      ? true
                      : null
                  }
                  defaultValue={formFieldsRef.current.shape}
                />
                <span
                  className={errors.improve ? "error checkmark" : "checkmark"}
                ></span>
              </CustomCheckbox>
              <CustomCheckbox htmlFor="overall">
                Overall
                <input
                  required
                  id="overall"
                  type="checkbox"
                  name="improve"
                  value="overall"
                  defaultChecked={
                    formFieldsRef.current.improve &&
                    formFieldsRef.current.improve.find((i) => i === "overall")
                      ? true
                      : null
                  }
                  defaultValue={formFieldsRef.current.overall}
                />
                <span
                  className={errors.improve ? "error checkmark" : "checkmark"}
                ></span>
              </CustomCheckbox>
            </HStack>
            {errors.improve && <ErrorMessage>{errors.improve}</ErrorMessage>}
          </VStack>
          <InputField>
            <MultiSelectDropdown
              className={errors.services ? "error" : ""}
              services={services}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
            />
            {errors.services && <ErrorMessage>{errors.services}</ErrorMessage>}
          </InputField>
          <VStack>
            <p>Are you missing any teeth?</p>
            <HStack w="100%">
              <CustomRadio htmlFor="yes">
                Yes
                <input
                  required
                  id="yes"
                  name="missingTeeth"
                  value="Yes"
                  type="radio"
                  defaultChecked={
                    formFieldsRef.current.missingTeeth === "Yes" ? true : null
                  }
                />
                <span
                  className={
                    errors.missingTeeth ? "error checkmark" : "checkmark"
                  }
                ></span>
              </CustomRadio>
              <CustomRadio htmlFor="no">
                No
                <input
                  id="no"
                  name="missingTeeth"
                  value="No"
                  type="radio"
                  defaultChecked={
                    formFieldsRef.current.missingTeeth === "No" ? true : null
                  }
                />
                <span
                  className={
                    errors.missingTeeth ? "error checkmark" : "checkmark"
                  }
                ></span>
              </CustomRadio>
            </HStack>
            {errors.missingTeeth && (
              <ErrorMessage>{errors.missingTeeth}</ErrorMessage>
            )}
          </VStack>
          <InputText
            placeholder="Guradian Name"
            type="text"
            name="guardianName"
            defaultValue={formFieldsRef.current.guardianName}
          />
          <HStack w="100%" justify="space-between">
            {!userExists ? (
              <OutlineButton
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  handleSaveDataBackButton();
                }}
              >
                Back
              </OutlineButton>
            ) : null}
            <PrimaryButton
              className="btn btn-primary ml-auto"
              type="button"
              onClick={() => {
                handleDataForValidation();
              }}
            >
              Next <ArrowRight size={24} />
            </PrimaryButton>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
}

export default Step2;
