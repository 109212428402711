import React, { useCallback, useContext } from "react";
import Axios from "axios";
import {
  VStack,
  HStack,
  InputField,
  InputText,
  PrimaryButton,
  CustomRadio,
  ErrorMessage,
} from "../../../styledComponents/GlobalElements";
import { useFormValidation } from "../../../globalComponents/validation/useFormValidation";
import { accountInfoValidation } from "../../../globalComponents/validation/validationConstraints";
import { GlobalContext } from "../../../globalComponents/context/GlobalContextApi";

var formLoopData = {};

function InfoStep({ formFields }) {
  const { handleValidation, errors } = useFormValidation({
    data: formLoopData,
    validations: {
      ...accountInfoValidation,
    },
  });

  const { setSnackbarMessage } = useContext(GlobalContext);

  const handleDataForValidation = useCallback(async () => {
    var data = new FormData(document.getElementById("updateInfo-form"));

    for (var [key, value] of data) {
      formLoopData[key] = value;
    }
    handleValidation(() => {
      // Callback fn runs on no errors
      Axios({
        method: "POST",
        data: formLoopData,
        withCredentials: true,
        url: "/patient/updateUserDetails",
      }).then((res) => {
        if (res) {
          setSnackbarMessage(res.data.msg);
        }
      });
    });
  }, [handleValidation, setSnackbarMessage]);

  return (
    <VStack
      as="form"
      id="updateInfo-form"
      padding="1rem"
      align="center"
      justify="center"
      itemSpace="1rem"
    >
      <HStack itemSpace="2rem" w="100%" responsive="576px" rItemSpace="1rem">
        <InputField>
          First Name
          <InputText
            className={errors.firstName ? "error" : ""}
            required
            placeholder="First Name"
            type="text"
            name="firstName"
            defaultValue={formFields.First_Name}
          />
          {errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
        </InputField>
        <InputField>
          Last Name
          <InputText
            className={errors.lastName ? "error" : ""}
            required
            placeholder="Last Name"
            type="text"
            name="lastName"
            defaultValue={formFields.Last_Name}
          />
          {errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
        </InputField>
      </HStack>
      <VStack>
        <p>Gender</p>
        <HStack w="100%">
          <CustomRadio htmlFor="male">
            Male
            <input
              required
              id="male"
              name="gender"
              value="male"
              type="radio"
              defaultChecked={formFields.Gender === "male" ? true : null}
            />
            <span
              className={errors.gender ? "error checkmark" : "checkmark"}
            ></span>
          </CustomRadio>
          <CustomRadio htmlFor="female">
            Female
            <input
              id="female"
              name="gender"
              value="female"
              type="radio"
              defaultChecked={formFields.Gender === "female" ? true : null}
            />
            <span
              className={errors.gender ? "error checkmark" : "checkmark"}
            ></span>
          </CustomRadio>
          <CustomRadio htmlFor="others">
            Others
            <input
              id="others"
              name="gender"
              value="others"
              type="radio"
              defaultChecked={formFields.Gender === "others" ? true : null}
            />
            <span
              className={errors.gender ? "error checkmark" : "checkmark"}
            ></span>
          </CustomRadio>
        </HStack>
        {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
      </VStack>
      <InputField>
        Age
        <InputText
          required
          placeholder="Age"
          type="Number"
          name="age"
          defaultValue={formFields.Age}
        />
        {errors.age && <ErrorMessage>{errors.age}</ErrorMessage>}
      </InputField>
      <InputField>
        State
        <InputText
          required
          placeholder="State"
          type="text"
          name="state"
          defaultValue={formFields.State}
        />
        {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
      </InputField>
      <InputField>
        City
        <InputText
          required
          placeholder="City"
          type="text"
          name="city"
          defaultValue={formFields.City}
        />
        {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
      </InputField>
      <InputField>
        Postal Code
        <InputText
          className={errors.postalCode ? "error" : ""}
          required
          placeholder="Postal Code"
          type="text"
          name="postalCode"
          defaultValue={formFields.Postal_Code}
        />
        {errors.postalCode && <ErrorMessage>{errors.postalCode}</ErrorMessage>}
      </InputField>
      <PrimaryButton
        className="ml-auto"
        type="button"
        onClick={() => {
          handleDataForValidation();
        }}
      >
        Update Information
      </PrimaryButton>
    </VStack>
  );
}

export default InfoStep;
